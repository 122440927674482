'use strict';
var _ = require('lodash');
var Backbone = require('backbone');
var ModelConnect = require('@telescope/tscom-connect');
var base64 = require('crypto-js/enc-base64.js');
var sha256 = require('crypto-js/hmac-sha256.js');

module.exports = ModelConnect.extend({
  versionCheck: '',
  version_id: '',

  /**
   * Override sync method to make requests to the Vote API.
   * @param {String}         crud    - Will be one of: 'create', 'read', 'update', 'destroy'.  We use the value to determine action type.
   * @param {Backbone.Model} model   - A reference to this model.
   * @param {Object}         options - A JS object with options for $.ajax.
   * @return {[type]}                [description]
   */
  sync: function (crud, model, options) {
    var qsp = this.paramsLiteralToQsp(model.toJSON());
    var b64hash = this.qspToHash(qsp);

    var params = {
      url: this.apiUrl + '?' + qsp,
      type: 'POST',
      crossDomain: true,
      dataType: 'json',
      data: 'Authorization=' + encodeURIComponent(b64hash)
    };

    var attributes = _.clone(model.toJSON());
    delete attributes.method;

    _.extend(params, attributes, options);

    return Backbone.sync(crud, model, params).done(this.syncSuccess).fail(this.syncFail);
  },

  fetchCategory: function(catId) {
    this.set({
      timestamp: (new Date().getTime().toString()),
      v: catId
    });
    return this.fetch();
  },

  /**
   * Callback for If AJAX send vote success
   * @param {[type]} response [description]
   */
  syncSuccess: function (response) {
    if (!response) response = {};
    var actionType = this.get('action_type');
    this.set({
      response: response,
      status: "success"
    });

    this.trigger('sync', this);
    this.trigger('sync:' + actionType, response);
  },
  fixedEncodeURIComponent: function (str) {
    return encodeURIComponent(str).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
  },
  /**
   * [paramsLiteralToQsp description]
   * @param  {Object} params [description]
   * @return {String}        [description]
   */
  paramsLiteralToQsp: function (params) {
    var pars = [];
    var paramsStr = '';
    var _INVALID_KEYS = ['status', 'response'];
    _.each(params, function (value, key) {
      if (_INVALID_KEYS.indexOf(key) === -1 && value !== undefined && value !== null) {
        pars.push(key + '=' + this.fixedEncodeURIComponent(value));
      }
    }.bind(this));
    paramsStr = pars.sort().join('&');
    return paramsStr;
  },
  /**
   * [qspToHash description]
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  qspToHash: function (data) {
    var secret = this.version_id + this.versionCheck;
    var hash = sha256(data, secret);
    return base64.stringify(hash);
  }
});
