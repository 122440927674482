'use strict';
const _ = require("lodash");

const template = require('./../templates/page.ejs');
const BaseView = require('./../views/base');
const ViewFooter = require('./../views/footer');
const ViewHeader = require('./../views/header');

module.exports = BaseView.extend({
  className: 'view-page',
  /**
     *
     */
  initialize: function ( options ) {
    _.bindAll(this, 'render');

    this.ads = options.ads;
    this.geo = options.geo;
  },
  /**
     *
     */
  render: function () {
    this.$el.html(template(this.model.get('copy')));
    //test if iframed
    //test for touch events support and if not supported, attach .no-touch class
    this.$el.addClass((top === self ? 'frame-false' : 'frame-true') + (!("ontouchstart" in document.documentElement) ? ' no-touch' : ''))
    this.$('header').append(new ViewHeader({ model: this.model.get('header'), ads: this.ads }).render().$el);

    const footer = new ViewFooter({ model: this.model.get('footer'), geo: this.geo  });
    this.$('footer').append( footer.render().$el );

    if( typeof footer.renderedToDom === 'function' ) {
      footer.renderedToDom();
    }

    return this;
  }
});
