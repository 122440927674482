'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var slugify = require('slugify');
var Model = require('./../models/category');
var constants = require('./../constants');

module.exports = Backbone.Collection.extend({
  model: Model,
  initialize: function () {
    this.listenTo(this, 'add', this.onModelAdded.bind(this));
  },

  onModelAdded: function(model) {
    var name = model.get('name');
    model.set('slug', slugify(name, {
      remove: constants.SLUGIFY.REGEX,
      lower: true
    }));
  },

  comparator: function (a, b) {
    return parseInt(a.get('order'), 10) - parseInt(b.get('order'), 10);
  },
  /**
     * [next description]
     * @param  {[type]}   option [description]
     * @return {Function}        [description]
     */
  next: function (option) {
    if (!this.contains(option)) {
      return false;
    }
    var model = this.at(this.indexOf(option) + 1);

    if (_.isUndefined(model)) {
      model = this.last();
    }

    return model;
  },
  /**
     * [prev description]
     * @param  {[type]} option [description]
     * @return {[type]}        [description]
     */
  prev: function (option) {
    if (!this.contains(option)) {
      return false;
    }
    var model = this.at(this.indexOf(option) - 1);

    if (_.isUndefined(model)) {
      model = this.first();
    }

    return model;
  }
});
