'use strict';

var _ = require("lodash");
var Backbone = require("backbone");

var base = function (options) {
  Backbone.View.call(this, options);
  this.isActiveView = true;
};

_.extend(base.prototype, Backbone.View.prototype, {
  events: function () {
    return {
      'destroyed': 'removeHandler'
    }
  },
  /**
   * [removeHandler description]
   * @return {[type]} [description]
   */
  removeHandler: function () {

    this.isActiveView = false;
    this.stopListening();
  },
  /**
   * [renderedToDom description]
   * @return {[type]} [description]
   */
  renderedToDom: function () {

  }
});

base.extend = Backbone.View.extend;

module.exports = base;
