let initialized = false;

window.dataLayer = window.dataLayer || [];

export function gtag() { 
  window.dataLayer.push(arguments); 
}

export function initializeGA4( measurementId ) {
  if( initialized ) return;
  
  var script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=' + measurementId;
  document.head.appendChild( script );

  gtag('js', new Date());
  gtag('config', measurementId );

  initialized = true;
}

export function trackPageView( pageTitle, route ) {
  gtag('event', 'page_view', {
    page_title: pageTitle,
    page_location: window.location.href,
    page_path: route || window.location.pathname
  });
}

export function trackLogin( method ) {
  gtag('event', 'login', {
    method: method
  });
}

export function trackShare( method ) {
  gtag('event', 'share', {
    method: method
  })
}

export function trackCustomEvent( action, label, category, nonInteraction ) {
  nonInteraction = !!nonInteraction;

  gtag('event', action, {
    event_label: label,
    event_category: category,
    non_interaction: nonInteraction,
  });
}

export function disableAdFeatures() {
  gtag('set', 'allow_ad_personalization_signals', false);
}