import slugify from 'slugify';
import { DEVICE } from '../constants';
import { getDevice } from './helpers';

const getQueryParamByName = require('tscom-util/src/getQueryParamByName');

let initialized = false;
let prevPageName = 'None';
let currentPageName = 'None';
const searchParams = window.location.search;
const utmParams = getUtmParams();

export function initialize(apiKey, devMode) {
  if( initialized ) return;

  devMode = devMode || false;

  //configure the SDK
  window.mParticle = {
    config: {
      isDevelopmentMode: devMode
    }
  };

  //load the SDK
  (function (t) {
    window.mParticle = window.mParticle || {};
    window.mParticle.EventType = {
      Unknown: 0,
      Navigation: 1,
      Location: 2,
      Search: 3,
      Transaction: 4,
      UserContent: 5,
      UserPreference: 6,
      Social: 7,
      Other: 8,
    };
    window.mParticle.eCommerce = { Cart: {} };
    window.mParticle.Identity = {};
    window.mParticle.config = window.mParticle.config || {};
    window.mParticle.config.rq = [];
    window.mParticle.config.snippetVersion = 2.3;
    window.mParticle.ready = function (t) {
      window.mParticle.config.rq.push(t);
    };
    var e = [
      "endSession",
      "logError",
      "logBaseEvent",
      "logEvent",
      "logForm",
      "logLink",
      "logPageView",
      "setSessionAttribute",
      "setAppName",
      "setAppVersion",
      "setOptOut",
      "setPosition",
      "startNewSession",
      "startTrackingLocation",
      "stopTrackingLocation",
    ];
    var o = ["setCurrencyCode", "logCheckout"];
    var i = ["identify", "login", "logout", "modify"];
    e.forEach(function (t) {
      window.mParticle[t] = n(t);
    });
    o.forEach(function (t) {
      window.mParticle.eCommerce[t] = n(t, "eCommerce");
    });
    i.forEach(function (t) {
      window.mParticle.Identity[t] = n(t, "Identity");
    });
    function n(e, o) {
      return function () {
        if (o) {
          e = o + "." + e;
        }
        var t = Array.prototype.slice.call(arguments);
        t.unshift(e);
        window.mParticle.config.rq.push(t);
      };
    }
    var dpId;
    var dpV;
    var config = window.mParticle.config;
    var env = config.isDevelopmentMode ? 1 : 0;
    var dbUrl = "?env=" + env;
    var dataPlan = window.mParticle.config.dataPlan;
    dataPlan &&
      ((dpId = dataPlan.planId),
      (dpV = dataPlan.planVersion),
      dpId &&
        (dpV && (dpV < 1 || dpV > 1e3) && (dpV = null),
        (dbUrl += "&plan_id=" + dpId + (dpV ? "&plan_version=" + dpV : ""))));
    var mp = document.createElement("script");
    mp.type = "text/javascript";
    mp.async = true;
    mp.src =
      "https://jssdkcdns.mparticle.com/js/v2/" + t + "/mparticle.js" + dbUrl;
    var c = document.getElementsByTagName("script")[0];
    c.parentNode.insertBefore(mp, c);
  })(apiKey);

  initialized = true;
}

export function logPageLoadEvent( pageName, skipPageHistory ) {
  if( !initialized ) return;

  const mParticle = window.mParticle;

  prevPageName = currentPageName;
  let customAttrs = {};

  if( !skipPageHistory ) {
    currentPageName = pageName;
    customAttrs = {
      'URL': window.location.origin + searchParams
    }
  }

  const globalAttrs = getGlobalCustomEventAttributes( pageName );

  mParticle.logEvent('Page Load', mParticle.EventType.Navigation, { ...globalAttrs, ...customAttrs });
}

export function logClickActionEvent( clickActionType, itemClickName, pathname = document.location.href ) {
  if( !initialized ) return;

  const mParticle = window.mParticle;

  const url = pathname.startsWith('http')? pathname :  
    `${document.location.origin}/${pathname.replace(/^\/|\$/g, '')}`;

  const globalAttrs = getGlobalCustomEventAttributes();
  const customAttrs = {
    'Click Action Type': clickActionType,
    'Item Clicked Name': itemClickName.trim(),
    'URL': url + searchParams
  }

  mParticle.logEvent('Click Action', mParticle.EventType.Navigation, { ...globalAttrs, ...customAttrs });
}

export const ShareEvent = {
  Action: {
    Facebook: 'Share',
    Twitter: 'Tweet'
  },
  Network: {
    Facebook: 'Facebook',
    Twitter: 'Twitter'
  },
  Destination: {
    Facebook: 'Timeline',
    Twitter: 'Personal'
  }
}

export function logShareEvent( network ) {
  if( !initialized ) return;

  const mParticle = window.mParticle;

  const globalAttrs = getGlobalCustomEventAttributes();
  const customAttrs = {
    'Social Network': network,
    'Social Action': ShareEvent.Action[network],
    'Destination': ShareEvent.Destination[network],
  }

  mParticle.logEvent('Social Share', mParticle.EventType.UserPreference, { ...globalAttrs, ...customAttrs });
}

export function logExitEvent( exitName ) {
  if( !initialized ) return;
  
  const mParticle = window.mParticle;

  const globalAttrs = getGlobalCustomEventAttributes();
  const customAttrs = { 
    'Exit Name': exitName.trim()
  }

  mParticle.logEvent('Exit', mParticle.EventType.Navigation, { ...globalAttrs, ...customAttrs });
}

function getUtmParams() {
  const keys = [
    'UTM Source',
    'UTM Medium',
    'UTM Campaign',
    'UTM Term',
    'UTM Content'
  ];

  let utmParams = {};

  keys.forEach( ( key ) => {
    const qsp = getUtmParamName( key );
    const value = getQueryParamByName( qsp );

    if( value !== null && value !== '' ) {
      utmParams[ key ] = value;
    }
  });

  return utmParams;
}

function getGlobalCustomEventAttributes( pageName ) {
  pageName = pageName || currentPageName;

  const device = getDevice();
  let mParticleDevice = 'Web';

  switch( device ) {
  case DEVICE.IOS:
    mParticleDevice = 'iOS';
    break;
  case DEVICE.ANDROID:
    mParticleDevice = 'Android';
    break;
  default:
    mParticleDevice = 'Web';
    break;
  }

  let attributes = {
    'Platform': 'Web',
    'Device Type': mParticleDevice,
    'Product': 'PCA',
    'Season': '2024',
    'Show': 'PCA',
    'Page Name': pageName.trim(),
    'Referring Page': prevPageName.trim(),
    ...utmParams
  };

  return attributes;
}

function getUtmParamName( value ) {
  return slugify( value, {
    replacement: '_',
    lower: true
  });
}