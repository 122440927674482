'use strict';
const _ = require("lodash");
const Backbone = require("backbone");

const template = require('./../templates/footer.ejs');
const templateCss = require('./../templates/footer-css.ejs');
const BaseView = require('./../views/base');
const CSSView = require('./css');
const { logClickActionEvent, logPageLoadEvent } = require("../util/mparticle");
const constants = require("../constants");

let styleId;

module.exports = BaseView.extend({
  className: 'view-footer',
  events: {
    'click a': 'trackClick',
    'click .ot-sdk-show-settings': 'trackOneTrust'
  },
  /**
   *
   */
  initialize: function (options) {
    _.bindAll(this, 'render', 'updateOneTrustButton');

    styleId = this.className + _.uniqueId();
    this.$el.addClass(styleId);
    this.listenTo(this.model, 'change:copy', this.render);
    this.geo = options.geo;
  },
  /**
   *
   */
  render: function () {
    this.CSSView = new CSSView({
      model: this.model.get('copy')['customizations'],
      namespace: '.' + styleId,
      template: templateCss
    });

    Backbone.$('head').append(this.CSSView.render().$el);
    this.$el.html(template(this.model.get('copy')));

    const isLocationUS = this.geo.get('country').toLowerCase() === constants.APP_REGION.US;

    if( isLocationUS ) {
      this.$el.find('.ot-sdk-show-settings').addClass('usa');
    }

    return this;
  },

  renderedToDom: function() {
    if( window.OneTrust ) {
      this.updateOneTrustButton();
    } else {
      window.addEventListener('Telescope:OptanonWrapper', this.updateOneTrustButton );
    }
  },

  updateOneTrustButton: function() {
    if( window.OneTrust.getGeolocationData().country != 'US') {
      this.$el.find('#ot-sdk-btn').text('Cookie Preferences');
    } else {
      this.$el.find('#ot-sdk-btn').text('Your Privacy Choices');
    }
  },

  trackClick: function(e) {
    const linkText = e.currentTarget.textContent || this.$(e.currentTarget).find('title').textContent;

    logClickActionEvent('Vote', linkText.trim(), e.currentTarget.href);
  },

  trackOneTrust: function( e ) {
    logClickActionEvent('One Trust', e.currentTarget.textContent, e.currentTarget.href);
    logPageLoadEvent( 'OneTrust Modal', true );
  },

  remove: function () {
    if (this.CSSView) {
      this.CSSView.remove();
    }
  }
});
