module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<%- namespace %> { <%- footer_display.toLowerCase()==='false'? 'display: none;' : '' %> <%- background_image_mobile.length>0? \"background: \"+(background_color.length>0?background_color: 'transparent')+\" url('\"+background_image_mobile+\"') top center no-repeat;\" : \"\" %> <%- background_image.length>0? \"background-size: 750px 90px;\" : \"\" %> <%- background_color.length>0? \"background-color: \"+background_color+\";\" : \"transparent\" %> } @media (min-width: 750px) { <%- namespace %> { <%- background_image.length>0? \"background: \"+(background_color.length>0?background_color: 'transparent')+\" url('\"+background_image+\"') top center no-repeat;\" : \"\" %> <%- background_image.length>0? \"background-size: 970px 90px;\" : \"\" %> } } <%- namespace %> .wrapper-text p, <%- namespace %> .wrapper-text a { <%- paragraph_color.length>0? 'color: '+paragraph_color+';' : '' %> } <%- namespace %> .copyright { <%- display_copyright.toLowerCase()==='false'? 'display: none;' : '' %> } <%- namespace %> .credit { <%- display_credit.toLowerCase()==='false'? 'display: none;' : '' %> } <%- namespace %> .terms { <%- display_terms.toLowerCase()==='false'? 'display: none;' : '' %> }"
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append( namespace )
    ; __append(" { ")
    ; __append( footer_display.toLowerCase()==='false'? 'display: none;' : '' )
    ; __append(" ")
    ; __append( background_image_mobile.length>0? "background: "+(background_color.length>0?background_color: 'transparent')+" url('"+background_image_mobile+"') top center no-repeat;" : "" )
    ; __append(" ")
    ; __append( background_image.length>0? "background-size: 750px 90px;" : "" )
    ; __append(" ")
    ; __append( background_color.length>0? "background-color: "+background_color+";" : "transparent" )
    ; __append(" } @media (min-width: 750px) { ")
    ; __append( namespace )
    ; __append(" { ")
    ; __append( background_image.length>0? "background: "+(background_color.length>0?background_color: 'transparent')+" url('"+background_image+"') top center no-repeat;" : "" )
    ; __append(" ")
    ; __append( background_image.length>0? "background-size: 970px 90px;" : "" )
    ; __append(" } } ")
    ; __append( namespace )
    ; __append(" .wrapper-text p, ")
    ; __append( namespace )
    ; __append(" .wrapper-text a { ")
    ; __append( paragraph_color.length>0? 'color: '+paragraph_color+';' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(" .copyright { ")
    ; __append( display_copyright.toLowerCase()==='false'? 'display: none;' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(" .credit { ")
    ; __append( display_credit.toLowerCase()==='false'? 'display: none;' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(" .terms { ")
    ; __append( display_terms.toLowerCase()==='false'? 'display: none;' : '' )
    ; __append(" }")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}