'use strict';

var CmsModel = require('@telescope/tscom-cms');
var _ = require('lodash');
var Backbone = require('backbone');
var $ = require('jquery');

module.exports = Backbone.Collection.extend({
  model: CmsModel,
  /**
   * We can't request multiple wids at the same time, so we'll
   * just have to have each model make the call.
   */
  fetch: function (options) {
    var options = _.isObject(options) ? options : {};
    var promises = _.invoke(this.models, 'fetch', options);
    return $.when.apply($, promises);
  }
});
