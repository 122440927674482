'use strict';
var _ = require("lodash");
var $ = require("jquery");
var Backbone = require("backbone");

module.exports = Backbone.Model.extend({
  defaults: {
    authType: null,
    isAuthorized: false,
    userName: null,
    userID: null,
    optins: {}
  },
  /**
   *
   */
  initialize: function (attributes, options) {
    this.controller = options.controller;

    this.listenTo(this, 'change:userID', this.defineUser, this);
    this.listenTo(this, 'change:authType', this.isEmailUser, this);

    this.initializeType();
  },
  /**
   *
   */
  initializeType: function () {
    const facebook = this.controller.Models.Facebook;
    if( !facebook ) {
      this.set({ authType: 'email' });
      return;
    }

    if (facebook.get("status") === "") { // if FB SDK hasn't loaded, add listener
      this.listenToOnce(facebook, 'change:status', this.initializeType.bind(this));
    } else { // once we know the SDK has loaded we can check the window
      switch (facebook.get("status")) {
      case 'connected':
        this.setUpFBUser();
        this.set({ authType: 'facebook' });
        break;
      case 'not_authorized':
        this.set({ authType: 'email' });
        break;
      case 'unknown':
      default:
        this.set({ authType: 'email' });
        break;
      }
    }
  },
  /**
   *
   */
  signInSuccess: function (type, data) {
    if (type === 'email') {
      if (!_.isNull(this.get('userID')) && this.get('userID') !== data) {
        this.controller.userLogout();
      }
      this.setUpEmailUser(data);
    } else if (type === 'facebook' && this.controller.Models.Facebook) {
      if ($.type(this.controller.Models.Facebook.get('userID')) != 'null') {
        this.setUpFBUser();
      } else {
        // Add listener for when the Facebook user data is available
        this.listenToOnce(this.controller.Models.Facebook, 'change:userID', function () {
          this.signInSuccess('facebook');
        }.bind(this));
      }
    }
  },
  /**
   *
   */
  setUpFBUser: function () {
    const facebook = this.controller.Models.Facebook;
    if( !facebook ) return;
    const fbModel = facebook.toJSON();

    if ( fbModel.status === 'connected') {
      this.set({
        authType: 'facebook',
        userName: fbModel.first_name,
        userID: fbModel.id,
        isAuthorized: true,
        fbEmail: fbModel.email || null,
        fbFirstName: fbModel.first_name,
        fbLastName: fbModel.last_name,
        fbGender: fbModel.gender,
        fbLocale: fbModel.locale,
        fbAgeRange: fbModel.age_range,
        fbName: fbModel.name
      });

    } else {
      // Add listener for when the Facebook user data is available
      this.listenToOnce(this.controller.Models.Facebook, 'change:userID', this.setUpFBUser.bind(this));
    }
  },
  /**
   *
   */
  setUpEmailUser: function (data) {

    this.set({
      authType: 'email',
      userName: data,
      userID: data,
      isAuthorized: true
    });
  },
  /**
   *
   */
  defineUser: function (model, value) {
    if ($.type(value) === 'null') {
      // if the user logs out we no longer want their email address in localstorage
      if (!_.isUndefined(localStorage['auth_email'])) {
        localStorage.removeItem('auth_email');
      }
    } else {

      if (this.get('authType') === 'email' && _.isUndefined(localStorage['auth_email'])) {
        localStorage['auth_email'] = this.get('userID');
      }
    }
  },
  deAuthorize: function () {


    switch (this.get('authType')) {
    case 'facebook':
      if( this.controller.Models.Facebook ) {
        this.controller.Models.Facebook.logout();
      };
      break;
    case 'email':

      break;
    default:

      // do nothing
      break;
    }

    this.clear().set(this.defaults);

  },
  /**
   *
   */
  isEmailUser: function (model, value) {
    if (value === 'email' && !_.isUndefined(localStorage['auth_email']) && this.get('userID') === null) {

      this.setUpEmailUser(localStorage['auth_email']);
    }
  }
});
