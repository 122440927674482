module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"wrapper-logo\"> <% if (sponsor_bug.image) { %> <div class=\"sponsor-bug\"> <% if (sponsor_bug.link.url) { %><a href=\"<%- sponsor_bug.link.url %>\" target=\"<%- sponsor_bug.link.target %>\"><% } %> <img src=\"<%- sponsor_bug.image %>\" alt=\"<%- sponsor_bug.alt %>\"> <% if (sponsor_bug.link.url) { %></a><% } %> </div> <% } %> <%- logoLink.length>0? '<a href=\" '+logoLink+' \" target=\"'+logo.link.target+'\">' : '' %> <div class=\"logo\"></div> <%- logoLink.length>0? '</a>' : '' %> </div> <aside class=\"aside-logo\"></aside>"
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"wrapper-logo\"> ")
    ;  if (sponsor_bug.image) { 
    ; __append(" <div class=\"sponsor-bug\"> ")
    ;  if (sponsor_bug.link.url) { 
    ; __append("<a href=\"")
    ; __append( sponsor_bug.link.url )
    ; __append("\" target=\"")
    ; __append( sponsor_bug.link.target )
    ; __append("\">")
    ;  } 
    ; __append(" <img src=\"")
    ; __append( sponsor_bug.image )
    ; __append("\" alt=\"")
    ; __append( sponsor_bug.alt )
    ; __append("\"> ")
    ;  if (sponsor_bug.link.url) { 
    ; __append("</a>")
    ;  } 
    ; __append(" </div> ")
    ;  } 
    ; __append(" ")
    ; __append( logoLink.length>0? '<a href=" '+logoLink+' " target="'+logo.link.target+'">' : '' )
    ; __append(" <div class=\"logo\"></div> ")
    ; __append( logoLink.length>0? '</a>' : '' )
    ; __append(" </div> <aside class=\"aside-logo\"></aside>")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}