'use strict';
var _ = require("lodash");
var Backbone = require("backbone");

var base = function (attrs, options) {
  options = options || {};
  this.cms = options.cms;
  this.textKey = options.textKey;

  Backbone.Model.apply(this, arguments);
};
_.extend(base.prototype, Backbone.Model.prototype, {
  defaults: function () {
    return {
      copy: {},
      uid: ''
    }
  },
  initialize: function () {

    if (this.cms) {
      this.listenTo(this.cms, 'change:text', function (model, value) {
        if (typeof this.updateCopy !== 'undefined' && this.updateCopy)
          this.updateCopy(model, value);
      }.bind(this))
      this.set({
        ads: this.cms.get('text')['ads']
      });
    }
  },
  /**
   *
   */
  fetch: function () {
    console.warn('The Base model does not support "fetch"');
  },
  /**
   *
   */
  save: function () {
    console.warn('The Base model does not support "save"');
  },
  /**
   *
   */
  destroy: function (options) {
    options = options ? _.clone(options) : {};
    var model = this;
    var wait = options.wait;

    var destroy = function () {
      model.stopListening();
      model.trigger('destroy', model, model.collection, options);
    };

    if (!wait) destroy();
  },
  /**
   * [updateCopy description]
   * @param  {[type]} model [description]
   * @param  {[type]} value [description]
   * @return {[type]}       [description]
   */
  updateCopy: function (model, value) {
    if (!this.textKey) {
      return;
    }

    this.set({
      copy: _.clone(value[this.textKey], true),
      ads: _.clone(value['ads'], true)
    });
  }
});
base.extend = Backbone.Model.extend;

module.exports = base;
