'use strict';
var _ = require("lodash");
var Backbone = require("backbone");

module.exports = Backbone.View.extend({
  tagName: 'style',
  className: 'view-css',
  initialize: function (options) {
    this.template = options.template;
    this.namespace = options.namespace || '';

    this.model = _.extend(_.clone(this.model, 'true'), {
      namespace: this.namespace
    });

    this.$el.prop('type', 'text/css');
  },
  /**
   *
   */
  render: function () {

    this.$el.append(this.template(this.model));
    return this;
  }
});
