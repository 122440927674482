'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');
var router = require('./routes/router');
var ModelCms = require('@telescope/tscom-cms');
var ModelConnect = require('./models/connect');
var ModelGeo = require('@telescope/tscom-geo');
var ModelFacebook = require('./models/facebook');
var ModelBase = require('./models/base');
var ModelAuth = require('./models/auth');
var ModelVote = require('./models/vote');
var CollectionCms = require('./collections/cms');
var CollectionVote = require('./collections/vote');
var CollectionCategory = require('./collections/category');
var CollectionCategoryGroup = require('./collections/category-group');
var CollectionLanguages = require('./collections/languages');
var PubSub = require('tscom-pubsub');
var CONSTANTS = require('./constants');
var APP_STATE = CONSTANTS.APP_STATE;
var SORTING_METHOD = CONSTANTS.SORTING_METHOD;
var ROUTES = CONSTANTS.ROUTES;
var CONNECT = CONSTANTS.CONNECT;
var isTrue = require('./util/helpers').isTrue;
var isMobile = require('./util/helpers').isMobile;
var getPlatform = require('./util/helpers').getPlatform;
var facebookShare = require('./util/helpers').facebookShare;
var mergeRecursive = require('./util/merge-recursive');
var delimitedStringToArray = require('./util/helpers').delimitedStringToArray;
var isOneTrustCategoryAllowed = require('./util/helpers').isOneTrustCategoryAllowed;
var getQueryParamByName = require('tscom-util/src/getQueryParamByName');
const { initializeGA4, disableAdFeatures } = require('./util/ga4');
const { initialize } = require('./util/mparticle');
const { getEmailOptins } = require('./util/get-email-optins');
require('../styles/main.scss');

// CONSTANTS
var ERROR_MSG_CONFIG = 'Controller: Unable to load environment configuration: ';
var AUTH_CONST = require('./constants').AUTH;

/**
 *
 * @param options - {
 * widget_id: 'data/data.json' // REQUIRED string
 * container: $('#myWidget') // REQUIRED string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * modal: $('#wrapper') // string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * uniqueId: 'name-type-namespace-datetime' // string
 * cssUrl: 'http://a1.telesocpe.tv/styles/main.css' // string (absolute path)
 * hashState: false // boolean
 * endpoints: {cms: 'https://widgetstate.votenow.tv/v1/state/'} // object
 * }
 */
function Controller(options) {

  options = options || {};
  options.widget_id = options.widget_id || 'd160c1e2e3309363';
  
  options.hashState = (getQueryParamByName('devmode') === 'true' || options.hashState || false);
  options.endpoints = _.extend({
    cms: 'https://widgetstate.votenow.tv/v1/state/'
  }, ($.type(options.endpoints) === 'object' ? options.endpoints : {}));

  this.options = options;
  this.Models = {};
  this.Collections = {};
  this.Views = {};
  this.Routers = {};
  this.devmode = false;
  this.appState = '';
  this.voteHistory = {};
  this.device = null;
  this.appRegion = null;
  this.isWidget = false;
  this._captcha = null;
  this._captcha_cb = null;
  this.writeInName = '';
  this.currentCatId = null;
  this.useMainConnectKeys = false;

  //OneTrust Analytics cookies permission check
  this.googleEnabled = false;

  //Detect the current environment.
  this.env = getQueryParamByName('env');

  this.listenTo(PubSub, 'userCloseModal', function() {
    this.stopListening(PubSub, 'postActionGet');
  });

  /* BEGIN THE LOADING PROCESS */
  //Load config.php
  this.loadEnvironmentConfig()
    //Merge endpoints
    .then(
      this.updateEndpoints.bind(this), //Success
      console.error.bind(this, ERROR_MSG_CONFIG + this.env) //Error
    )
    // start CMS loading
    .then(this.loadCmsEnvironment.bind(this))
    .then(this.widgetLoaded.bind(this))
    .then(this.loadContestantCmsModels.bind(this))
    .then(this.loadOtherCmsModels.bind(this))
    .then(this.loadGeo.bind(this))
    .then(this.setAppRegion.bind(this))
    .then(function() {
      if( window.OneTrust ) {
        this.optanonWrapper();
      } else {
        window.addEventListener('Telescope:OptanonWrapper', this.optanonWrapper.bind(this));
      }
    }.bind(this))
    .then(this.initializeMParticle.bind(this))
    .then(this.loadFacebookModel.bind(this))
    .then(this.loadConnectModel.bind(this))
    .then(this.loadAuthModel.bind(this))
    .then(this.loadGoogleModel.bind(this))
    .then(this.loadOtherModels.bind(this))
    .then(this.componentsLoaded.bind(this))
    .then(this.setupListeners.bind(this))
    .then(this.setUpRouter.bind(this))
}

Controller.prototype = _.extend({
  widgetLoaded: function () {
    //set up main cms poll
    var freq = parseInt(this.Models.Cms.get('text').app_settings.cms_polling_in_seconds.main, 10) || CONSTANTS.POLL.CMS_DEFAULT;
    this.Models.Cms.set('updateFrequency', freq);
    this.Models.Cms.poll = true;
    this.Models.Cms.pollData();

    this.useMainConnectKeys = isTrue(this.Models.Cms.get("text").app_settings.use_main_key);

    //set app state
    this.setAppState();

    // check if embed widget
    this.checkSource();

    //set device
    this.device = this.isWidget? CONSTANTS.DEVICE.WIDGET : getPlatform();
  },
  loadContestantCmsModels: function () {
    var cmsModels = [];
    var cms = this.Models.Cms;

    var defaultWids = delimitedStringToArray(cms.get('settings').contestant_wids);
    var queryWids = delimitedStringToArray(getQueryParamByName('v_wid'));
    var sids = delimitedStringToArray(getQueryParamByName('v_sid'));
    var wids = (queryWids.length > 0) ? queryWids : defaultWids;

    var freq = parseInt(this.Models.Cms.get('text').app_settings.cms_polling_in_seconds.contestants, 10) || 0;

    for (var i = 0; i < wids.length; i++) {
      cmsModels.push(new ModelCms({
        id: wids[i],
        wid: wids[i],
        sid: sids[i],
        apiUrl: this.options.endpoints.cms,
        updateFrequency: freq
      }, { ignoreQSP: true, poll: freq > 0 }));
    }

    var cmsCollection = this.Collections.CmsContestants = new CollectionCms(cmsModels);

    return cmsCollection.fetch();
  },
  fetchAndUpdateContestants: function () {
    return this.Collections.CmsContestants.fetch().then(this.updateCategoryGroup.bind(this));
  },

  loadOtherCmsModels: function () {
    var cmsModels = new Array();
    var widgets = {
      CmsCategory: {
        wid: (getQueryParamByName('c_wid').length === 0 ? undefined : getQueryParamByName('c_wid')) || this.Models.Cms.get('settings').category_wid,
        sid: (getQueryParamByName('c_sid').length === 0 ? undefined : getQueryParamByName('c_sid'))
      },
      CmsCategoryGroup: {
        wid: (getQueryParamByName('cg_wid').length === 0 ? undefined : getQueryParamByName('cg_wid')) || this.Models.Cms.get('settings').category_group_wid,
        sid: (getQueryParamByName('cg_sid').length === 0 ? undefined : getQueryParamByName('cg_sid'))
      }
    };

    var freq = parseInt(this.Models.Cms.get('text').app_settings.cms_polling_in_seconds.category_and_group, 10) || 0;

    _.forEach(widgets, function (value, key) {
      if (!_.isEmpty(value.wid)) {
        this.Models[key] = new ModelCms({
          id: key,
          wid: value.wid,
          sid: value.sid,
          apiUrl: this.options.endpoints.cms,
          updateFrequency: freq
        }, { ignoreQSP: true, poll: (freq > 0) });
        cmsModels.push(this.Models[key]);
      }
    }.bind(this));

    //Add the models to our custom collection.
    var cmsCollection = this.Collections.Cms = new CollectionCms(cmsModels);

    //set languages model
    this.Collections.Languages = new CollectionLanguages();

    //The collection will call .fetch on each of our models and return a $.Deferred
    return cmsCollection.fetch();

  },
  loadGeo: function () {
    // Akamai Geo Location
    this.Models.Geo = new ModelGeo({
      countries: this.Models.Cms.get('settings').countries
    });
    return this.Models.Geo.fetch();
  },
  setAppRegion: function () {
    this.appRegion = this.Models.Geo.get('geoheaders').country;
  },
  loadConnectModel: function () {
    this.createConnectModels('ConnectData');
    this.createConnectModels('ConnectVote');
    this.createConnectModels('ConnectOptin');

    this.listenTo(this.Models.ConnectData, 'sync:get', function (res) {
      if (res.response_code && res.response_code === '20') {
        var categoryId = this.Models.ConnectData.get('v');
        var catVoteString = res[categoryId + "_votestring"];
        var catVoteHistory = catVoteString? JSON.parse(catVoteString) : {};
        var categoryModel = this.Collections.CategoryGroup.getCategoryById(categoryId);

        this.updateCategoryVotes(categoryModel, catVoteHistory);
      } else {
        PubSub.trigger('category-votes-updated');
      }

      PubSub.trigger('postActionGet');
    }.bind(this));
  },
  createConnectModels: function(name) {
    var settings = this.Models.Cms.get('settings');

    // Set up Connect model (for GET call, fetching remaining votes)
    this.Models[name] = new ModelConnect({
      apiKey: this.useMainConnectKeys ? settings.apiKey_main : settings.apiKey
    }, {
      apiUrl: this.options.endpoints.connect,
      apiSecret: this.useMainConnectKeys ? settings.secretKey_main: settings.secretKey,
      version_id: this.useMainConnectKeys ? settings.version_id_main: settings.version_id,
      versionCheck: this.useMainConnectKeys ? CONNECT.VERSION_CHECK_MAIN: CONNECT.VERSION_CHECK
    });
    this.Models[name].apiUrl = this.options.endpoints.vote;

    this.listenTo(this.Models[name], 'error', function (err) {
      console.error('Connect fetch error', err);
    }.bind(this));
  },
  loadFacebookModel: function () {
    const authSettings = this.Models.Cms.get('text')['view_auth']['customizations'];
    const isReauthEnabled = authSettings['fb_reauth_enabled'];
    const isFacebookLoginEnabled = authSettings['show_button_facebook'] === 'true';

    if(!isFacebookLoginEnabled) return;

    // Set up Facebook model
    this.Models.Facebook = new ModelFacebook({
      appId: this.Models.Cms.get("social").fb.id,
      reauthEnabled: isTrue(isReauthEnabled),
      version: CONSTANTS.FACEBOOK.VERSION
    }, {
      controller: this,
    });
    if (CONSTANTS.FACEBOOK.MANUAL_LOGIN_DEVICES.indexOf(this.device) > -1) {
      this.Models.Facebook.set('isManualFlow', true);
    }

  },

  loadAuthModel: function () {
    // Set up Auth model
    this.Models.Auth = new ModelAuth({}, {
      controller: this
    });
    PubSub.listenTo(this.Models.Auth, 'change:isAuthorized', function (model) {
      PubSub.trigger('authViewClose', model);
      if (this.Models.Auth.get('isAuthorized') && !(this.appState === APP_STATE.COUNTDOWN_CLOSED)) {

        // fetch for the remaining votes once user is logged in
        this.Models.ConnectData.set({
          timestamp: (new Date().getTime().toString()),
          user_id: this.Models.Auth.get('userID'),
          method: (this.Models.Auth.get('authType') === 'email' ? 'email' : 'fb')
        });

        if (this.useMainConnectKeys && this.Models.ConnectData.get('v')) {
          this.Models.ConnectData.fetch();
        }
      }

      if (!this.Models.Auth.get('isAuthorized')) {
        this.resetCategoryVotes()
      }

      PubSub.trigger('change:user', model);
    }.bind(this));
  },

  optanonWrapper: function() {
    if (this.googleEnabled ) { return; }

    this.loadGoogleModel();
  },

  isTrackingAllowed: function() {
    const performanceCookies = this.Models.Cms.get('settings').onetrust_performance_cookie_ids;
    const countries = this.Models.Cms.get('settings').countries;

    // If it's worldwide, rely on OneTrust
    if( countries.trim() === '' ) {
      return isOneTrustCategoryAllowed(performanceCookies);
    } else {
      // If it's not worldwide, allow tracking
      return true;
    }
  },

  isAdvertisingAllowed: function() {
    const adsCookies = this.Models.Cms.get('settings').onetrust_ads_cookie_ids;
    const countries = this.Models.Cms.get('settings').countries;

    // If it's worldwide, rely on OneTrust
    if( countries.trim() === '' ) {
      return isOneTrustCategoryAllowed(adsCookies);
    } else {
      // If it's not worldwide, allow tracking
      return true;
    }
  },

  loadGoogleModel: function () {
    if (this.isTrackingAllowed() ) {
      this.googleEnabled = true;
      
      initializeGA4( this.Models.Cms.get('settings').google_analytics );
    }
  },

  loadOtherModels: function () {

    var cmsCopy = this.getActiveLanguageCopy();

    this.Models.Header = new ModelBase({
      copy: cmsCopy.view_header,
      uid: this.options.uniqueId
    }, { textKey: 'view_header', cms: this.Models.Cms });

    var footerCopy = cmsCopy.view_footer;
    if (isMobile()) {
      footerCopy.text = footerCopy.mobile_text;
    }
    this.Models.Footer = new ModelBase({
      copy: footerCopy,
      uid: this.options.uniqueId
    }, { textKey: 'view_footer', cms: this.Models.Cms });

    this.Models.User = new ModelBase({
      copy: cmsCopy.view_user,
      uid: this.options.uniqueId,
      window: this.Models.Cms.get('windowStatus'),
      user: this.Models.Auth.get('userName')
    }, { textKey: 'view_user', cms: this.Models.Cms });

    this.listenTo(this.Collections.Languages, 'update:activeModel', function () {
      var newCopy = this.getActiveLanguageCopy();
      this.Models.Header.set({ copy: newCopy.view_header });
      this.Models.Footer.set({ copy: newCopy.view_footer });
      this.Models.User.set({ copy: newCopy.view_user });
    }.bind(this));
    this.listenTo(this.Models.Cms, 'change:windowStatus', function () { this.Models.User.set({ window: this.Models.Cms.get('windowStatus') }); }.bind(this));
    this.listenTo(this.Models.Auth, 'change:userName', function () { this.Models.User.set({ user: this.Models.Auth.get('userName') }); }.bind(this));

    this.Models.Page = new ModelBase({
      copy: '',
      uid: this.options.uniqueId,
      header: this.Models.Header,
      footer: this.Models.Footer
    }, { cms: this.Models.Cms });

  },
  setOnetrustAcceptListener: function(){

    $(document.body).on('click','#onetrust-accept-btn-handler, #onetrust-reject-all-handler, .save-preference-btn-handler, #accept-recommended-btn-handler' , function(){
      const shouldDisableAds = !this.isTrackingAllowed() || !this.isTrackingAllowed();
      if(shouldDisableAds && this.googleEnabled) disableAdFeatures();

      if(!this.isTrackingAllowed()) {
        this.googleEnabled = false;
      } else {
        this.loadGoogleModel();
      }

    }.bind(this));
  },

  categoryGroupFilter: function (data) {
    return data.active === '1';

  },
  categoryFilter: function (data) {
    return data.active === '1';
  },
  componentsLoaded: function () {

    var categoryGroup = _.clone(_.filter(this.Models.CmsCategoryGroup.get('data'), this.categoryGroupFilter.bind(this)) || [], true);
    var category = _.clone(_.filter(this.Models.CmsCategory.get('data'), this.categoryFilter.bind(this)) || [], true);
    //var vote = _.clone(_.where(this.Models.Cms.get('data'), { active: '1' }) || [], true);
    var voteLimit = parseInt(this.Models.Cms.get('settings').vote_limit);

    var vote = this.Collections.CmsContestants.pluck('data');
    vote = [].concat.apply([], vote);
    vote = _.where(vote, { active: '1' });

    var mergedData = this.mergeDataSets(categoryGroup, category, vote);
    this.Collections.CategoryGroup = new CollectionCategoryGroup(mergedData, {
      appRegion: this.appRegion,
    });

    this.Collections.CategoryGroup.setUpAllModels();
    this.Collections.CategoryGroup.setUpAvailableGroups();

    this.Collections.Category = new CollectionCategory();
    this.Collections.Category.add(category, {
      remainingVotes: voteLimit
    });

    categoryGroup = category = vote = mergedData = null;
  },

  setupListeners: function () {
    this.listenTo(this.Models.CmsCategoryGroup, 'change:data', this.updateCategoryGroup.bind(this));
    this.listenTo(this.Models.CmsCategory, 'change:data', this.updateCategoryGroup.bind(this));
    this.listenTo(this.Models.Cms, 'change:data', this.updateCategoryGroup.bind(this));
    this.listenTo(this.Models.Cms, 'change:windowStatus', this.checkWindow.bind(this));
    this.listenTo(this.Models.Cms, 'change:sid', this.handleSidChange.bind(this));
    this.listenTo(this.Models.Auth, 'change:optins', this.handleOptinChange.bind(this));

    this.listenTo(PubSub, 'vote', this.delegateVote.bind(this));
    this.listenTo(PubSub, 'write-in', this.delegateWriteInVote.bind(this));
    this.listenTo(PubSub, 'facebookLogin', this.authFacebook.bind(this))
    this.listenTo(PubSub, 'emailLogin', this.authEmail.bind(this))
    this.listenTo(PubSub, 'userLogout', this.userLogout.bind(this))
    this.listenTo(PubSub, 'facebookShare', this.shareFacebook.bind(this));
    this.listenTo(PubSub, 'shareVote', this.shareVote.bind(this));
    this.listenTo(PubSub, 'fbManualAuth', this.fbManualLogin.bind(this));

    this.listenTo(this.Models.ConnectVote, 'sync:vote', this.voteSuccess.bind(this));
    this.listenTo(this.Models.ConnectVote, 'error', function () {
      PubSub.trigger('navigate', 'error/vote');
    }.bind(this));

    if( this.Models.Facebook ) {
      this.listenTo(this.Models.Facebook, 'change', function (fbModel) {
        if('status' in fbModel.changed || 'permissions' in fbModel.changed) {
          const optins = getEmailOptins( this.Models.Auth.get('optins') );
          const isFbEmailRequired = Object.keys( optins ).length > 0;
          const isValid =  !isFbEmailRequired || isFbEmailRequired && fbModel.hasFbEmailPermission();
  
          if (fbModel.get('status') === 'connected' && isValid) {
            this.authFacebookSuccess();
          }
        }
      }.bind(this));
    }

    this.setOnetrustAcceptListener();

  },
  initializeMParticle: function() {
    if( this.isTrackingAllowed() ) {
      const settings = this.Models.Cms.get('settings');
      const domain = window.location.hostname;
      const nonProd = ['-dev', '-local', '-test'];
      const isDev = nonProd.some( (value) => {
        return domain.includes( value );
      });

      initialize( settings.mparticle_key, isDev );
    }
  },
  setUpRouter: function () {

    const Router = router({ hashState: this.options.hashState });

    this.Routers.Router = new Router({
      el: this.options.container,
      modal: this.options.modal,
      uid: this.options.uniqueId,
      controller: this
    });

    if (this.options.hashState) {
      Backbone.history.start({ pushState: true });
    }

    if (!this.options.hashState) {
      this.Routers.Router.navigate(ROUTES.LANDING, { trigger: true })
    }
    //PCA-38
    if (this.options.route) {
      this.Routers.Router.navigate(this.options.route, { trigger: true });
    }
  },
  /**
     * [delegateVote description]
     * @param  {[type]} model [description]
     * @return {[type]}       [description]
     */
  delegateVote: function (data) {

    if (!this.Models.Auth.get('isAuthorized')) {
      this.Routers.Router.auth();
      this.stopListening(PubSub, 'postActionGet');
      this.listenToOnce(PubSub, 'postActionGet', this.castVote.bind(this, data))
      return;
    }

    this.castVote(data);

  },
  setWriteInName: function (name) {
    //filter write in to only characters allow in name
    //also accomodate for international names/characters
    this.writeInName = name.replace(/[^0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\ \,\.\'\-\&\$]/gi, '');
  },
  delegateWriteInVote: function (data) {
    data.model = new ModelVote({
      id: data.id,
      writein: data.writeIn,
      category_id: data.categoryId
    }, {
      controller: this
    });
    this.setWriteInName(data.writeIn);
    this.delegateVote(data);
  },
  /**
     * [authFacebook description]
     * @param  {Function} cb [description]
     * @return {[type]}      [description]
     */
  authFacebook: function (cb, data) {
    if(!this.Models.Facebook) return;

    const optins = getEmailOptins( this.Models.Auth.get('optins') );
    const isFbEmailRequired = Object.keys( optins ).length > 0;
    const isValid = !isFbEmailRequired || (isFbEmailRequired && this.Models.Facebook.hasFbEmailPermission());
    const scope = data.scope;

    let options = {};

    if(!isValid) {
      options['auth_type'] = AUTH_CONST.AUTH_TYPE.REREQUEST;
    }
    this.Models.Auth.set('optins', data.optins || {});
    this.Models.Facebook.login({...options, ...scope}).then(function(response){
      if(typeof cb === 'function'){
        const optins = getEmailOptins( this.Models.Auth.get('optins') );
        const isFbEmailRequired = Object.keys( optins ).length > 0;
        const isValid = !isFbEmailRequired || (isFbEmailRequired && this.Models.Facebook.hasFbEmailPermission());

        cb(response);
        if(isValid){
          this.Models.Auth.signInSuccess('facebook')
        }
      }
    }.bind(this), function(response){
      if(typeof cb === 'function'){
        cb(response);
      }
    });
  },
  /**
     * [authFacebookSuccess description]
     * @return {[type]} [description]
     */
  authFacebookSuccess: function () {
    this.Models.Auth.signInSuccess('facebook');
    PubSub.trigger('authViewClose', this.Models.Auth);
  },
  /**
     * [authEmail description]
     * @param  {[type]} email [description]
     * @return {[type]}       [description]
     */
  authEmail: function (data) {
    var email = data.email;
    this.Models.Auth.signInSuccess('email', email);
    this.Models.Auth.set('optins', data.optins || {});
  },

  handleOptinChange: function() {
    const { Auth, ConnectOptin } = this.Models;
    const optins = Auth.get('optins');

    if (!optins || !Object.keys( optins ).length) {
      return;
    }

    if (Auth.get('isAuthorized')) {
      var userParams = {
        email: Auth.get('fbEmail') || Auth.get('userID'),
        first_name: Auth.get('fbFirstName') || null,
        last_name: Auth.get('fbLastName') || null
      }

      const optins = Auth.get('optins');

      this.Models.ConnectOptin.set(this.getDataForVoteApi());
      ConnectOptin.save({
        action_type: 'optin',
        ...userParams,
        ...optins
      });

    } else {
      this.listenToOnce(this.Models.Auth, 'change:isAuthorized', this.handleOptinChange.bind(this));
    }
  },
  /**
     * [fbManualLogin login with token after manual login]
     * @param {[string]} token [access token from fb]
     */
  fbManualLogin: function (token) {
    if( !this.Models.Facebook ) return;
    this.Models.Facebook.set('accessToken', token);
    this.Models.Facebook.setUserDataWithToken();
  },
  /**
     * [shareFacebook description]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  shareFacebook: function (params) {
    var copy = params.copy_wid? '&copy_wid=' + params.copy_wid : '';
    var wid = this.Models.Cms.get('wid');
    var sid = this.Models.Cms.get('useSID');
    var url =  this.options.endpoints.canonical + 'share?id=' + params.id + '&category_id=' + params.category_id + copy + '&wid=' + wid;
    if (!!sid) {
      url = url  + '&sid=' + sid;
    }
    facebookShare( url );
  },
  /**
     * [getDataForVoteApi gets object of user info and other reusable for vote api]
     * @return {[type]} object     [description]
     */
  getDataForVoteApi: function () {
    var settings = this.Models.Cms.get('settings');
    var data = {
      apiKey: (this.useMainConnectKeys) ? settings.apiKey_main : settings.apiKey,
      timestamp: (new Date().getTime().toString()),
      user_id: this.Models.Auth.get('userID'),
      method: (this.Models.Auth.get('authType') === 'email' ? 'email' : 'fb'),
      device: this.device || '',
      country: this.Models.Geo.get('geoheaders').country,
      state: this.Models.Geo.get('geoheaders').region,
      city: this.Models.Geo.get('geoheaders').city
    };
    if (data.method === 'fb') {
      _.extend(data, {
        fb_email: this.Models.Auth.get('fbEmail'),
        fb_first_name: this.Models.Auth.get('fbFirstName'),
        fb_last_name: this.Models.Auth.get('fbLastName')
      })
    }
    return data;
  },
  /**
     * [castVote description]
     * @param  {[type]} model [description]
     * @return {[type]}       [description]
     */
  castVote: function (data) {
    var model = data.model;
    var voteCount = data.voteCount;

    var nominee = model.get('id');
    var category = model.get('category_id');
    var v = data.writeIn? category: `${category}-${nominee}`;

    var params = {
      action_type: 'vote',
      v: v,
      total: voteCount
    };

    if (data.writeIn) {
      params.writein = data.writeIn;
    }
    this.Models.ConnectVote.set(this.getDataForVoteApi());
    this.Models.ConnectVote.save(params);
  },

  voteSuccess: function (response) {

    var code = response.response_code || '';
    var voteId = this.Models.ConnectVote.get('v').split("-");
    var categoryId = voteId[0];

    switch (code) {
    case '20':
      if (response.overall_votestring) {
        var voteString = response.overall_votestring || '{}';
        var overallVoteHistory = JSON.parse(voteString) || {};
        this.updateRemainingVotes(overallVoteHistory);
      }

      var categoryModel = this.Collections.CategoryGroup.getCategoryById(categoryId);
      var catVoteString = response[categoryId + "_votestring"];
      var route = categoryModel.get('slug');

      if (catVoteString) {
        var catVoteHistory = JSON.parse(catVoteString) || {};
        var contestantId = voteId[1];
        var contestantModel = this.Collections.CategoryGroup.getOptionByCatAndNominee(categoryId, contestantId, 'id');
        route = `${categoryModel.get('slug')}/${contestantModel.get('slug')}`;
        this.updateCategoryVotes(categoryModel, catVoteHistory);
      }

      PubSub.trigger('navigate', `${ROUTES.THANKS}/${route}`);
      break;
    case '21':
      // PCA-670:
      // sample resposne: {"response_code":"21","cat11_votestring":"{\"vote\":{\"cat11-total\":25,\"cat11-A1\":25}}"}
      try{
        var catTotalKey = categoryId + '-total';
        var catVoteStringKey = categoryId + '_votestring';

        if(response[catVoteStringKey]){
          var voteStringObj = JSON.parse(response[catVoteStringKey]);
          var result = {};
          result[categoryId] = voteStringObj['vote'][catTotalKey];
          this.updateRemainingVotes(result);
        }

      }catch(e){
        console.error(e);
      }
      this.Routers.Router.error(ROUTES.OVERLIMIT);
      break;
    case '22':
      this.voteHistory = {};
      this.Routers.Router.error(ROUTES.GEO);
      break;
    default:
      this.voteHistory = {};
      this.Routers.Router.error(ROUTES.ERROR_VOTE);
      break;
    }
  },

  fetchCategory: function(catId) {
    var isAuthorized = this.Models.Auth.get('isAuthorized');

    if (this.Models.ConnectData.get('v') === catId || !this.useMainConnectKeys) {
      return;
    } else if (!isAuthorized) {
      this.Models.ConnectData.set('v', catId, { silent: true })
      return;
    }

    var promise = $.Deferred();

    if(isAuthorized && !this.Models.ConnectData.get('user_id')){
      this.Models.ConnectData.set({
        timestamp: (new Date().getTime().toString()),
        user_id: this.Models.Auth.get('userID'),
        method: (this.Models.Auth.get('authType') === 'email' ? 'email' : 'fb')
      });
    }

    this.listenTo(PubSub, 'category-votes-updated', function(){
      promise.resolve();
    });

    if( this.Models.Cms.get('windowStatus') === 1 ) {
      this.Models.ConnectData.fetchCategory(catId);
    } else {
      promise.resolve();
    }

    return promise;
  },

  clearCategory: function() {
    this.Models.ConnectData.set('v', '', { silent: true });
  },
  /**
     * [submites extra incentive vote when sharing]
     * @param {[type]} model [vote option model]
     */
  shareVote: function (data) {
    var model = data.model;
    var actionType = data.action_type;
    var nominee = model.get('id');
    var category = model.get('category_id');
    var group = this.Collections.CategoryGroup.getCategoryById(category).get('group_id');

    // var optins = this.Models.Auth.get('optins');
    var params = {
      action_type: actionType,
      group: group,
      category: category,
      contestant: nominee
    };

    this.Models.ConnectVote.set(this.getDataForVoteApi());
    this.Models.ConnectVote.save(params);
  },
  /**
     * [userLogout description]
     * @return {[type]} [description]
     */
  userLogout: function () {
    if( this.Models.AuthView ) {
      this.Models.AuthView.destroy();
    }
    this.Models.Auth.deAuthorize();
    if( this.Models.Facebook ) {
      this.Models.Facebook.clearUserData();
    }
    this.Models.ConnectVote.clear();
  },
  /**
     * [checkWindow description]
     * @param  {[type]} model   [description]
     * @param  {[type]} value   [description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
  checkWindow: function () {
    var windowStatus = this.Models.Cms.get('windowStatus');
    var previousWindowStatus = this.Models.Cms.previous('windowStatus');

    switch (windowStatus) {
    /**
               * OPEN WINDOW
               */
    case 1:
      if (previousWindowStatus === 0) {
        if( this.Models.Facebook ) {
          this.Models.Facebook.checkUserLoginStatus();
        }
        this.Models.Auth.initializeType();
        PubSub.trigger('navigate', ROUTES.LANDING);
      }
      break;
      /**
             * CLOSED WINDOW
             */
    case 0:
      // Show Closed Window
      if (this.appState === APP_STATE.COUNTDOWN_CLOSED) {
        PubSub.trigger('navigate', ROUTES.WINDOW);
        this.userLogout();
      }
      break;
    }
  },
  /**
     *
     */
  setAppState: function () {
    var cms = this.Models.Cms;
    if (this.Models.Cms.get('windowStatus')) {
      this.appState = APP_STATE.OPEN;
    } else {
      var closedState = cms.get('text').app_settings.closed_state;
      if (isTrue(closedState.winners)) {
        this.appState = APP_STATE.WINNERS;
      } else if (isTrue(closedState.voting_closed)) {
        this.appState = APP_STATE.VOTING_CLOSED;
      } else {
        //default is closed page with countdown
        this.appState = APP_STATE.COUNTDOWN_CLOSED;
      }
    }
    PubSub.trigger('appStateChange', this.appState);
  },
  /**
     *
     */
  loadEnvironmentConfig: function () {
    var env = this.env? '/?env=' + this.env : '';

    return $.ajax('/config/endpoints.php' + env);
  },
  /**
     *
     */
  updateEndpoints: function (response) {
    this.options.endpoints = _.extend(this.options.endpoints, response);
    return this.options;
  },
  /**
     *
     */
  loadCmsEnvironment: function () {
    var CmsMain = this.Models.Cms = new ModelCms({
      wid: this.options.widget_id,
      apiUrl: this.options.endpoints.cms
    }, { controller: this, poll: false });
    return CmsMain.fetch();
  },

  updateCategoryVotes: function(categoryModel, catVoteString) {
    var categoryId = categoryModel.get('id');
    var voteLimit = parseInt(this.Models.Cms.get('settings').vote_limit);
    var catTotal = catVoteString[`${categoryId}-total`];
    categoryModel.set('remainingVotes', voteLimit - (parseInt(catTotal) || 0));

    var voteCollection = categoryModel.get('voteCollection');

    _.each(voteCollection.models, function(voteModel) {
      var key = `${voteModel.get('category_id')}-${voteModel.get('id')}`;
      var voteCount = catVoteString[key]? catVoteString[key]: 0;
      voteModel.set('voteCount', voteCount);
    }.bind(this));

    PubSub.trigger('category-votes-updated', categoryModel.get('remainingVotes'));
  },

  resetCategoryVotes: function() {
    var voteLimit = parseInt(this.Models.Cms.get('settings').vote_limit);

    _.each(this.Collections.CategoryGroup.allModels, function(item) {
      item.set('remainingVotes', voteLimit);
      var voteCollection = item.get('voteCollection');

      _.each(voteCollection.models, function(cat) {
        cat.set('voteCount', 0);
      }.bind(this))

    }.bind(this))

    PubSub.trigger('category-votes-updated');
  },

  updateRemainingVotes: function (overallVoteString) {
    var voteString = overallVoteString || {};
    var voteLimit = parseInt(this.Models.Cms.get('settings').vote_limit);

    _.each(this.Collections.CategoryGroup.allModels, function (model) {
      var catId = model.get('id');

      var votedCount = voteString[catId] || 0;

      if (votedCount) {
        model.set('remainingVotes', voteLimit - (parseInt(votedCount) || 0));
      } else {
        model.set('remainingVotes', voteLimit);
      }
    }.bind(this));
  },

  mergeDataSets: _.debounce(function (categoryGroup, category, vote) {
    _.forEach(categoryGroup, function (group) {
      var id = group.id;
      var categoryOptions = _.where(category, { group_id: id });
      _.forEach(categoryOptions, function (cat) {
        var id = cat.id;
        var voteOptions = _.where(vote, { category_id: id });
        var sortRandomized = isTrue(this.Models.Cms.get('text').view_vote.randomized);
        var sortAlphabetical = (!sortRandomized && !sortCustom && isTrue(this.Models.Cms.get('text').view_vote.alphabetical));
        var sortCustom = (!sortRandomized && !sortAlphabetical && isTrue(this.Models.Cms.get('text').view_vote.custom));
        if (sortRandomized) {
          voteOptions = _.shuffle(voteOptions);
        }
        cat.voteData = voteOptions;

        cat.voteCollection = new CollectionVote(null,_.extend({
          controller: this,
          devmode: this.devmode,
          version_id: this.Models.Cms.get('settings').version_id,
          apiUrl: this.options.endpoints.vote,
          sortMethod: sortAlphabetical? SORTING_METHOD.ALPHABETICAL: sortCustom? SORTING_METHOD.CUSTOM: null
        }));
        cat.voteCollection.add(voteOptions,{
          controller: this
        });
      }.bind(this));

      group.categoryData = categoryOptions;
      group.categoryCollection = new CollectionCategory();
      group.categoryCollection.add(categoryOptions);
    }.bind(this));
    return categoryGroup;
  }, 1000, { 'leading': true }),
  updateCategoryGroup: _.debounce(function () {

    var categoryGroup = _.clone(_.filter(this.Models.CmsCategoryGroup.get('data'), this.categoryGroupFilter.bind(this)) || [], true);
    var category = _.clone(_.where(this.Models.CmsCategory.get('data'), this.categoryFilter.bind(this)) || [], true);

    //var vote = _.clone(_.where(this.Models.Cms.get('data'), { active: '1' }) || [], true);
    var vote = this.Collections.CmsContestants.pluck('data');
    vote = [].concat.apply([], vote);
    vote = _.where(vote, { active: '1' });

    var mergedData = this.mergeDataSets(categoryGroup, category, vote);
    if (this.Collections.CategoryGroup instanceof CollectionCategoryGroup) {
      this.Collections.CategoryGroup.set(mergedData, { merge: true });
      this.Collections.CategoryGroup.setUpAllModels();
    }

    if (this.Collections.Category instanceof CollectionCategory) {
      this.Collections.Category.set(category, { merge: true });
    }
    categoryGroup = category = vote = mergedData = null;
  }, 1000, { 'leading': true }),
  /**
     * handleSidChange
     * @return {undefined}
     */
  handleSidChange: function () {
    // PCA-618
    this.updateConnectKeys();

    var previousAppState = this.appState;
    this.setAppState();

    this.fetchAndUpdateContestants();
    this.checkWindow();

    if ((previousAppState !== this.appState) && (this.appState !== APP_STATE.COUNTDOWN_CLOSED)) {
      PubSub.trigger('navigate', ROUTES.LANDING);
    }

  },

  // PCA-618
  updateConnectKeys: function(){
    var settings = this.Models.Cms.get("settings");
    var useMain = isTrue(this.Models.Cms.get("text").app_settings.use_main_key);

    // return early if no need to update
    if(useMain === this.useMainConnectKeys) return;

    var apiKey = useMain ? settings["apiKey_main"] : settings["apiKey"];
    var versionId = useMain ? settings['version_id_main'] : settings['version_id'];
    var versionCheck = useMain ? CONNECT.VERSION_CHECK_MAIN : CONNECT.VERSION_CHECK;


    this.Models.ConnectData.set({apiKey: apiKey});
    this.Models.ConnectData.version_id = versionId;
    this.Models.ConnectData.versionCheck = versionCheck;

    this.Models.ConnectOptin.set({ apiKey: apiKey });
    this.Models.ConnectOptin.version_id = versionId;
    this.Models.ConnectOptin.versionCheck = versionCheck;

    this.Models.ConnectVote.set({ apiKey: apiKey });
    this.Models.ConnectVote.version_id = versionId;
    this.Models.ConnectVote.versionCheck = versionCheck;

    this.useMainConnectKeys = useMain;

  },
  getActiveLanguageCopy: function() {
    var languageModel = this.Collections.Languages.getActive();
    var cmsCopy = languageModel ? languageModel.get('text') : this.Models.Cms.get('text');

    var lang = cmsCopy.app_settings.lang? cmsCopy.app_settings.lang: '';
    document.documentElement.setAttribute('lang',lang);

    return cmsCopy;
  },

  getActiveLanguageGroups: function() {
    var languageModel = this.Collections.Languages.getActive();
    var cmsData = languageModel ? languageModel.get('data') : this.Collections.CategoryGroup.models;
    return cmsData;
  },

  loadLanguageCmsModel: function (wid) {
    if (!wid) {
      this.Collections.Languages.resetActive();
      return;
    }

    var languageModel = this.Collections.Languages.get(wid);

    if (languageModel) {
      this.Collections.Languages.setActive(wid);
      return $.Deferred().resolve(languageModel);
    }

    var freq = parseInt(this.Models.Cms.get('text').app_settings.cms_polling_in_seconds.language, 10) || 0;

    var languageCms = new ModelCms({
      id: wid,
      wid: wid,
      apiUrl: this.options.endpoints.cms,
      updateFrequency: freq
    }, { controller: this, poll: freq > 0 });

    this.Collections.Languages.add(languageCms);
    this.Collections.Languages.setActive(wid, {silent: true});

    return languageCms.fetch().then(this.handleInternationalLanguage.bind(this));
  },
  /**
     *
     */
  handleInternationalLanguage: function () {
    var model = this.Collections.Languages.getActive();

    var cmsCopy = _.cloneDeep(this.Models.Cms.get('text'));
    cmsCopy = mergeRecursive(cmsCopy, model.get('text'));

    var cmsData = _.cloneDeep(this.Collections.CategoryGroup.models);

    _.forEach(cmsData, function (value, key) {

      if (key > model.get('data').length -1) {
        return;
      }

      var data = model.get('data').filter((item)=> { return item.id === value.id });
      if(data) {
        value.attributes.name = data[0].name;
      }

    }.bind(this));

    model.set('text', cmsCopy);
    model.set('data', cmsData);
    this.Collections.Languages.setActive(model.get('wid'));
  },

  checkSource() {
    var widgetParam = getQueryParamByName('source');
    if ((widgetParam === CONSTANTS.WIDGET_QSP) && (window.self !== window.top)) {
      this.isWidget = true;
      document.documentElement.classList.add('widget');
    }
  }
}, Backbone.Events);

module.exports = function (options) {
  new Controller(options);
};
