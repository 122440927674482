'use strict';

var Backbone = require('backbone');
var _ = require("lodash");

var template = require('./../templates/modal.ejs');
var BaseView = require('./../views/base');
var PubSub = require('tscom-pubsub');
var isMobile = require('./../util/helpers').isMobile;

module.exports = BaseView.extend({
  tagName: 'div',
  className: 'view-modal',
  /**
   *
   */
  initialize: function () {
    _.bindAll(this, 'render');

    this.mouseListener = this.handleClick.bind(this);
    this.addMouseListener();
  },
  /**
   *
   */
  render: function () {
    this.$el.html(template());

    //test for touch events support and if not supported, attach .no-touch class
    this.$el.addClass((!("ontouchstart" in document.documentElement) ? 'no-touch' : ''))
    this.$('.modal-content').append((_.isObject(this.model.view) ? this.model.view.render().$el : this.model.view));
    return this;
  },

  handleClick: function(e) {
    var targetClass = e.target.classList;
    var shouldClose = targetClass.contains('modal-layout') || targetClass.contains('modal-window') || targetClass.contains('modal-wrapper');

    if (shouldClose) {
      e.preventDefault();
      PubSub.trigger('userCloseModal');
    }
  },

  addMouseListener: function() {
    if (isMobile()) {
      window.addEventListener('touchend', this.mouseListener);
    } else {
      window.addEventListener('mousedown', this.mouseListener);
    }
  },

  removeMouseListener: function() {
    window.removeEventListener('touchend', this.mouseListener);
    window.removeEventListener('mousedown', this.mouseListener);
  },

  remove: function() {
    this.removeMouseListener();
    Backbone.View.prototype.remove.call(this);
  }

});
