module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<%- namespace %> .view-thanks-content figure { padding: <%- item_image_padding.length>0? item_image_padding : '0' %>; } <%- namespace %> .view-thanks-content figure img { border-radius: <%- item_image_radius.length>0? item_image_radius : '0' %>; } <%- namespace %>.view-thanks .view-thanks-content .wrapper-text h1 { <%- headline1_color.length>0? 'color: '+headline1_color+';' : '' %> } <%- namespace %>.view-thanks .view-thanks-content .wrapper-text p.paragraph1 { <%- headline2_color.length>0? 'color: '+headline2_color+';' : '' %> } <%- namespace %>.view-thanks .view-thanks-content .wrapper-text h2 { <%- paragraph1_color.length>0? 'color: '+paragraph1_color+';' : '' %> } <%- namespace %>.view-thanks .view-thanks-content .wrapper-text h3.paragraph2 { <%- paragraph2_color.length>0? 'color: '+paragraph2_color+';' : '' %> } <%- namespace %>.view-thanks .view-thanks-content .wrapper-sharing p strong { <%- share_headline_color.length>0? 'color: '+share_headline_color+';' : '' %> }"
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append( namespace )
    ; __append(" .view-thanks-content figure { padding: ")
    ; __append( item_image_padding.length>0? item_image_padding : '0' )
    ; __append("; } ")
    ; __append( namespace )
    ; __append(" .view-thanks-content figure img { border-radius: ")
    ; __append( item_image_radius.length>0? item_image_radius : '0' )
    ; __append("; } ")
    ; __append( namespace )
    ; __append(".view-thanks .view-thanks-content .wrapper-text h1 { ")
    ; __append( headline1_color.length>0? 'color: '+headline1_color+';' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(".view-thanks .view-thanks-content .wrapper-text p.paragraph1 { ")
    ; __append( headline2_color.length>0? 'color: '+headline2_color+';' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(".view-thanks .view-thanks-content .wrapper-text h2 { ")
    ; __append( paragraph1_color.length>0? 'color: '+paragraph1_color+';' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(".view-thanks .view-thanks-content .wrapper-text h3.paragraph2 { ")
    ; __append( paragraph2_color.length>0? 'color: '+paragraph2_color+';' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(".view-thanks .view-thanks-content .wrapper-sharing p strong { ")
    ; __append( share_headline_color.length>0? 'color: '+share_headline_color+';' : '' )
    ; __append(" }")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}