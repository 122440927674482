'use strict'
var _ = require('lodash');
var Backbone = require('backbone');

var base64 = require("crypto-js/enc-base64.js");
var sha256 = require("crypto-js/hmac-sha256.js");

var CONSTANTS = require("../constants");


module.exports = Backbone.Model.extend({

  apiUrl: 'https://voteapi.votenow.tv/smsapi',

  defaults: {
    apiKey: ''
  },

  /**
   * Internal options and values
   */
  secretKey: '',
  version_id: '',
  device: 'unknownpc',
  response: null,
  versionCheck: CONSTANTS.CONNECT.VERSION_CHECK_OPTIN,

  /**
   * Initialize
   * @param  {Object} attr    [description]
   * @param  {Object} options [description]
   */
  initialize: function (attr, options) {
    _.bindAll(this, 'syncSuccess', 'syncError');

    if (typeof options === 'object') {
      for (var prop in options) {
        if (typeof this[prop] !== 'undefined') {
          this[prop] = options[prop];
        }
      }
    }

  },

  /**
   * Override sync method to make requests to the Vote API.
   * @param {String}         crud    - Will be one of: 'create', 'read', 'update', 'destroy'.  We use the value to determine action type.
   * @param {Backbone.Model} model   - A reference to this model.
   * @param {Object}         options - A JS object with options for $.ajax.
   * @return {[type]}                [description]
   */
  sync: function (crud, model, options) {
    var qsp = this.paramsLiteralToQsp(model.toJSON());
    var b64hash = this.qspToHash(qsp);
    var params = {
      url: this.urlRoot(),
      type: 'POST',
      crossDomain: true,
      dataType: 'json',
      data: 'Authorization=' + encodeURIComponent(b64hash),
      headers: {
        Authorization: b64hash
      }
    };

    _.extend(params, options);

    return Backbone.sync(crud, model, params).done(this.syncSuccess).fail(this.syncFail);
  },

  /**
   * Fetch
   * @param  {Object} options [description]
   */
  fetch: function (options) {
    this.set({ action_type: 'get' }, { silent: true });
    return this.sync('read', this, options);
  },

  /**
   * Save
   * @param  {Object} attrs   [description]
   * @param  {Object} options [description]
   * @return {[type]}         [description]
   */
  save: function (attrs, options) {
    attrs = attrs || {};
    // attrs.action_type = attrs.action_type || this.get('action_type') || 'vote';

    delete attrs.response;
    delete attrs.status;
    this.unset('response', { silent: true });
    this.unset('status', { silent: true });

    this.set(attrs, { silent: true });
    return this.sync('create', this, options);
  },

  /**
   * Override Backbone's urlRoot to generate the URL with hashed params.
   * @return {String} [description]
   */
  urlRoot: function () {
    var params = this.paramsLiteralToQsp(this.toJSON());

    return this.apiUrl + '?' + params;
  },

  /**
   * Connect doesn't support 'destroy' so let's warn the user.
   * @param {Object} options [description]
   */
  destroy: function () {
    console.warn('Connect API does not support the "destroy" method.');
  },

  /**
   * Callback for If AJAX send vote success
   * @param {[type]} response [description]
   */
  syncSuccess: function (response) {
    if (!response) response = {};
    this.set({
      response: response,
      status: "success"
    });

    this.trigger('sync', this);
  },

  /**
   * Callback for AJAX send vote fail
   * @param {[type]} response [description]
   */
  syncError: function () {
    this.set({
      response: {},
      status: 'error'
    });

    this.trigger('error', this);
  },

  /**
   * [qspToHash description]
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  qspToHash: function (data) {
    var secret = this.version_id + this.versionCheck;
    var hash = sha256(data, secret);
    return base64.stringify(hash);
  },

  /**
   * [paramsLiteralToQsp description]
   * @param  {Object} params [description]
   * @return {String}        [description]
   */
  paramsLiteralToQsp: function (params) {
    var pars = [];

    var paramsStr;
    _.each(params, function (value, key) {
      pars.push(key + '=' + this.fixedEncodeURIComponent(value));
    }.bind(this));
    paramsStr = pars.join('&');
    return paramsStr;
  },

  /**
   * [fixedEncodeURIComponent description]
   * @param  {String} str [description]
   * @return {[type]}     [description]
   */
  fixedEncodeURIComponent: function (str) {
    return encodeURIComponent(str).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
  }
});
