'use strict';
var _ = require("lodash");
var $ = require("jquery");
var Backbone = require("backbone");
var modelConnect = require('@telescope/tscom-connect');
var PubSub = require('tscom-pubsub');
var CONSTANTS = require("../constants");

function defineProtocolForCorsXhr(url) {
  if (url.search(/^http[s]?\:\/\//) === -1) {
    url = (!$.support.cors ? document.location.protocol : 'https:') + url;
  }
  return url;
};

module.exports = modelConnect.extend({
  url: '',
  defaults: function () {
    return {
      // vote data
      firstname: "",
      lastname: "",
      active: "0",
      secondaryinfo: "",
      thirdinfo: "",
      bio: "",
      video: "",
      eliminated: "0",
      moreinfoURL: "",
      id: "",
      image: "",
      image_retina: "",
      name: "",
      category_id: "",
      video_player_type: '',
      video_poster_image: '',
      winner: '0',
      thanks_video_embed: '',
      image_share: '',
      order: '',
      // vote API requirements
      apiKey: "categoryvote",
      timestamp: new Date().getTime().toString(), // 13 digit
      optin: "", // 1|0
      country: "",
      state: "",
      user_id: "",
      method: "", // fb|email
      group: "", // number
      category: "", // number
      contestant: "" // alphanumeric - single letter followed by number(s) i.e. A1
    };
  },
  apiUrl: 'https://voteapi.votenow.tv/vote',
  versionCheck: CONSTANTS.CONNECT.VERSION_CHECK,
  versionCheckMain: CONSTANTS.CONNECT.VERSION_CHECK_MAIN,
  xhr: null,
  saveParams: [
    'apiKey',
    'timestamp',
    'optin',
    'country',
    'state',
    'user_id',
    'method',
    'group',
    'category',
    'contestant',
    'fb_age_range',
    'fb_email',
    'fb_first_name',
    'fb_gender',
    'fb_last_name'
  ],
  /**
   *
   */
  initialize: function (attrs, options) {
    // _.bindAll(this, 'syncSuccess', 'syncError');

    try {
      if (_.isUndefined(options.controller)) {
        throw Error('The controller is a dependency. Please inject the controller into the check-in model.');
      }
    } catch (e) {
      console.log(e);
    }

    this.controller = options.controller;

    this.secretKey = options.secretKey;
    this.version_id = options.version_id;

    this.apiUrl = defineProtocolForCorsXhr(options.apiUrl || this.apiUrl);
  },
  /**
   * @desc override tscom-connect fetch with Backbone default
   * @param options
   * @returns {*}
   */
  fetch: function () {
    console.warn('The vote model does not support "fetch"');
  },
  /**
   * @desc override tscom-connect fetch with Backbone default
   * @param attrs
   * @param options
   * @returns {*|Session}
   */
  save: function () {
    return Backbone.Model.prototype.save.apply(this, arguments);
  },
  /**
   * destroy - not supported
   */
  destroy: function () {
    console.warn('The vote model does not support "destroy"');
  },
  /**
   * @desc Override sync method - if devmode or options.ajaxSync===false, write to localStorage, otherwise make requests to the Vote API via default Backbone.sync
   *
   * @param {string} crud - Will be one of: 'create', 'read', 'update', 'destroy'.  We use the value to determine action type.
   * @param {Backbone.Model} model - A reference to this model.
   * @param {object} options - A JS object with options for $.ajax.
   */
  sync: function (crud, model, options) {

    options = _.extend({
      ajaxSync: (this.controller.devmode === false)
    }, (options || {}));

    var qsp = this.paramsLiteralToQsp(_.pick(model.toJSON(), this.saveParams));
    var b64hash = this.qspToHash(qsp);

    var params = {
      url: this.apiUrl + '?' + qsp,
      type: 'POST',
      crossDomain: true,
      dataType: 'json',
      data: 'Authorization=' + encodeURIComponent(b64hash)
    };

    _.extend(params, options);

    this.xhr = Backbone.sync(crud, model, params);

    return this.xhr;
  },
  /**
   *
   * @param response
   */
  saveSuccess: function (callback, model, response) {

    // if we don't get any valid data
    if (!(_.isObject(response) && _.size(response) > 0)) {
      PubSub.trigger('navigate', 'error/vote');
      return false;
    }
    // spoorfing success
    if (this.controller.devmode) {
      response.response_code = '20';
    }
    switch (response.response_code || '') {
    case '20':
      callback(response);
      // this.save({vote: true},{ajaxSync: false}); // write to localStorage
      break;
    case '21':
      PubSub.trigger('navigate', 'error/overlimit');
      // this.save({vote: false},{ajaxSync: false}); // write to localStorage
      break;
    case '22':
      PubSub.trigger('navigate', 'error/geo');
      break;
    default:
      PubSub.trigger('navigate', 'error/vote');
      // this.save({vote: false},{ajaxSync: false}); // write to localStorage
      break;
    }
  },

  abortRequest: function () {
    if (_.isFunction(this.xhr.abort))
      this.xhr.abort();
  }
});
