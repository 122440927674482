'use strict';
var _ = require("lodash");
var Backbone = require("backbone");

var template = require('./../templates/thanks.ejs');
var templateCss = require('./../templates/thanks-css.ejs');

var BaseView = require('./../views/base');
var PubSub = require('tscom-pubsub');
var CSSView = require('./css');
var createCalendar = require('./../util/calendar');
var styleId;
var getDevice = require('./../util/helpers').getDevice;
var CONSTANTS = require('./../constants').SHARE_VOTE;
var WRITE_IN_ID = require('./../constants').WRITE_IN.CONTESTANT_ID;
var ROUTES = require('./../constants').ROUTES;
var isMobile = require('./../util/helpers').isMobile;
var ModelSms = require('../models/sms');
const { logShareEvent, logExitEvent, ShareEvent, logClickActionEvent } = require("../util/mparticle");

module.exports = BaseView.extend({
  className: 'view-thanks',
  events: function () {
    return _.extend(_.result(BaseView.prototype, 'events'), {
      'click .close': 'close',
      'click .btn-thanks-twitter': 'voteOnTwitterClick',
      'click .share-btn-twitter': 'shareToTwitterClick',
      'click .btn-thanks-prev': 'toPrev',
      'click .btn-thanks-next': 'toNext',
      'click .btn-thanks-all': 'toAll',
      'click .btn-thanks-again': 'toAgain',
      'click .share-btn-facebook': 'shareToFacebookClick',
      'click #checkbox-for-createCalendarId': 'toggleLabelClass',
      'submit .sms-optin-form': 'onSmsFormSubmit',
      'keyup  .telNo': 'onPhoneChange',
      'click .more': 'onClickMore',
      'click .download-app a': 'onDownloadApp',
      'click .suggested-categories li a': 'onSuggestedCategoryClick'
    })
  },

  smsModel: null,

  /**
     *
     */
  initialize: function (options) {
    _.bindAll(this, 'render');

    this.options = options || {};

    styleId = this.className + _.uniqueId();
    this.$el.addClass(styleId);
    this.listenTo(this.model, 'change:copy', this.render);
    this.calendarOptn = false;
    this.isLocationUS = this.options.isLocationUS;
    this.submitted = false;
    this.cmsSettings = options.cmsSettings;

    this.voteOption = this.model.get('voteOption').toJSON();
    this.category = this.model.get('categoryGroupCollection').getCategoryById(this.voteOption.category_id);
    this.categoryHashtag = this.category.get('hashtag');


    if( this.model.get('copy').sms.enable_sms_optin === 'true' ) {
      var apiKey = this.cmsSettings.apiKey_optin;
      var versionId = this.cmsSettings.version_id_optin;
      var secret = this.cmsSettings.secretKey_optin;
  
      // Set up Connect model (for GET call, fetching remaining votes)
      this.smsModel = new ModelSms({
        apiKey: apiKey,
        carrier: '0',
        shortcode: '97979'
      }, {
        apiUrl: this.options.endpoints.sms,
        apiSecret: secret,
        version_id: versionId
      });
    }
  },
  /**
    * [renderedToDOM description]
    * @return {[type]} [description]
    */
  renderedToDom: function () {
    if( this.model.get('copy').calendar.enable_calendar_optin === 'true' ) {
      this.generateCalendar();
    }
  },
  /**
     *
     */
  render: function () {

    this.CSSView = new CSSView({
      model: this.model.get('copy')['customizations'],
      namespace: '.' + styleId,
      template: templateCss
    });

    Backbone.$('head').append(this.CSSView.render().$el);

    var suggestedCategories = this.generateSuggestedCat();

    this.$el.html(template(_.extend(this.voteOption, {
      copy: this.model.get('copy'),
      isLocationUS: this.isLocationUS,
      download_app: this.model.get('copy').download_app[getDevice() || 'desktop'],
      writeIn: this.voteOption.id === WRITE_IN_ID,
      suggestedCategories
    })));

    this.$('.feedback-msg').addClass('hidden');

    return this;
  },

  generateSuggestedCat: function() {
    var suggestedCategories = [];
    var count = parseInt(this.model.get('copy').categories_suggestion.count) || 0;

    if (this.category.get('remainingVotes') > 0 || !count) {
      return suggestedCategories;
    }

    var groups = this.model.get('categoryGroupCollection');
    var availableGroups = groups.availableGroupsIds;
    var categories = groups.allModels.filter(function(item){
      return item.get('remainingVotes') > 0 && (availableGroups.indexOf(item.get('group_id')) > -1);
    });

    // Define how many random categories we are displaying
    var catCount = categories.length > count? count: categories.length;
    var randomIndexes = [];

    if (!catCount) {
      return suggestedCategories;
    }

    do {
      var random = Math.floor((Math.random() * categories.length));
      if(randomIndexes.indexOf(random) < 0) {
        randomIndexes.push(random)
      }
    } while (randomIndexes.length < catCount)

    randomIndexes.forEach(function(index) {
      const groupSlug = groups.length > 1 ? groups.getGroupById(categories[index].get('group_id')).get('slug') : '';

      suggestedCategories.push({
        route: `${groupSlug}/${categories[index].get('slug')}`,
        copy: categories[index].get('name')
      });
    })

    return suggestedCategories;
  },
  /**
     * [generateCalendar description]
     * @return {[type]} [description]
     */
  generateCalendar: function () {
    if (this.calendarOptn) {
      return;
    }
    var copy = this.model.get('copy').calendar;
    var ics = copy.mobile_ics.eastern;
    var start = new Date(copy.start_date);
    var end = new Date(copy.end_date);
    var clientDate = new Date().toString();
    var offset = 0;
    if (clientDate.indexOf('Pacific Daylight Time') > 0 || clientDate.indexOf('Pacific Standard Time') > 0 || clientDate.indexOf('PDT') > 0 || clientDate.indexOf('PST') > 0) {
      //3 hours from eastern time
      offset = 3 * 60 * 60 * 1000;
      ics = copy.mobile_ics.pacific;
    } else if (clientDate.indexOf('Central Daylight Time') > 0 || clientDate.indexOf('Central Standard Time') > 0 || clientDate.indexOf('CDT') > 0 || clientDate.indexOf('CST') > 0) {
      //1 hours from eastern time
      offset = 1 * 60 * 60 * 1000;
      ics = copy.mobile_ics.central;
    }
    var myCalendar = createCalendar({
      options: {
        class: 'my-class',
        // You can pass an ID. If you don't, one will be generated for you
        id: 'createCalendarId'
      },
      data: {
        // Event title
        title: copy.title,

        // Event start date
        start: new Date(start.getTime() + offset),

        // Event duration (IN MINUTES)
        duration: parseInt(copy.duration_in_minutes, 10),

        // You can also choose to set an end time
        // If an end time is set, this will take precedence over duration
        end: new Date(end.getTime() + offset),

        // Event Address
        address: copy.address,

        // Event Description
        description: copy.description
      }
    });

    var customLabel = document.createElement("LABEL");
    customLabel.htmlFor = 'checkbox-for-createCalendarId';
    customLabel.classList.add('calLabel');
    customLabel.innerHTML = copy.checkbox_label;

    this.$('#addToCalendar').append(myCalendar);
    this.$('#createCalendarId').prepend(customLabel);
    this.calendarOptn = true;

    //update ical and outlook links to use actual ics files since the data url doesn't work on mobile web
    if (isMobile()) {
      this.$('.icon-ical').attr('href', ics);
      this.$('.icon-outlook').attr('href', ics);
    }
    var download = copy.title.replace(/[^0-9a-zA-Z\ ]/gi, '').toLowerCase().split(' ').join('_');
    this.$('.icon-ical').attr('download', download);
    this.$('.icon-outlook').attr('download', download);
  },
  onDownloadApp: function() {
    logExitEvent( 'Thanks Modal - Download App' );
  },

  onClickMore: function() {
    logExitEvent( 'Thanks Modal - Promo Button ' );
  },
  /**
     * [close description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
  close: function (e) {

    e.preventDefault();
    this.$el.focus();
    PubSub.trigger('closeModal');
  },
  /**
     * [toNext description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
  toPrev: function (e) {
    e.preventDefault();

    var voteOption = this.model.get('voteOption');
    var currentCategoryId = voteOption.get('category_id');
    var groups = this.model.get('categoryGroupCollection');

    this.$el.focus();
    var prevCategory = this.model.get('categoryGroupCollection').getPrevCategoryById(currentCategoryId);
    var route = `${groups.getGroupById(prevCategory.get('group_id')).get('slug')}/${prevCategory.get('slug')}`;

    logClickActionEvent('Vote', 'TY Modal - Left Arrow', route);
    PubSub.trigger('navigate', route);
  },

  toNext: function (e) {
    e.preventDefault();

    var voteOption = this.model.get('voteOption');
    var currentCategoryId = voteOption.get('category_id');
    var groups = this.model.get('categoryGroupCollection');

    this.$el.focus();
    var nextCategory = this.model.get('categoryGroupCollection').getNextCategoryById(currentCategoryId);
    var route = `${groups.getGroupById(nextCategory.get('group_id')).get('slug')}/${nextCategory.get('slug')}`;

    logClickActionEvent('Vote', 'TY Modal - Right Arrow', route);

    PubSub.trigger('navigate', route);
  },

  onSuggestedCategoryClick: function(e) {
    logClickActionEvent('Vote', 'TY Modal - Suggested Subcategory', e.target.href);
  },
  /**
     * [toAll description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
  toAll: function (e) {
    e.preventDefault();
    this.$el.focus();
    PubSub.trigger('navigate', ROUTES.LANDING);
  },
  /**
     * [toAgain description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
  toAgain: function (e) {
    e.preventDefault();
    const category = this.model.get('category');
    const group = this.model.get('group');
    const groups = this.model.get('categoryGroupCollection');
    const groupSlug = groups.length > 1 ? group : '';

    this.$el.focus();

    const route = `${groupSlug}/${category}`;
    PubSub.trigger('navigate', route);
    logClickActionEvent('Vote', e.currentTarget.textContent, route);
  },

  onPhoneChange: function (e) {
    if (e.keyCode === 13) { return }
    if (this.submitted) { this.submitted = false }

    var val = this.$('input[name=phone-number]').val();
    var isValid = this.phoneFormatValidation(val);

    this.$('.success').addClass('hidden');
    this.$('.error').toggleClass('hidden', isValid || val.length === 0);
    this.$('.telNo').toggleClass('invalid', !isValid && val.length !== 0);

    if (val.length > 0 && isValid) {
      this.$('.sms-submit').attr('disabled', false);
    } else {
      this.$('.sms-submit').attr('disabled', true);
    }
  },

  getNumericPhone: function (val) {
    return val.replace(/[- )(]/g, '');
  },

  phoneFormatValidation: function (val) {
    var phonePattern = Backbone.Validation.patterns.phone;
    var validPhoneNumber = phonePattern.test(val);

    return validPhoneNumber;
  },

  onSmsFormSubmit: function (e) {
    e.preventDefault();

    if (this.submitted) return;

    this.$('.success').addClass('hidden');

    var val = this.$('input[name=phone-number]').val();
    if (this.phoneFormatValidation(val)) {

      if( this.smsModel ) {
        this.smsModel.save({
          identifier: this.getNumericPhone(val),
          timestamp: Math.floor(Date.now() / 1000)
        });
      }

      this.$('.success').removeClass('hidden');
      this.$('input[name=phone-number]').val('');
      this.submitted = true;
    } else {
      this.$('.error').removeClass('hidden');
    }
  },

  twitterVote: function () {
    PubSub.trigger('shareVote', {
      model: this.model.get('voteOption'),
      action_type: CONSTANTS.TWITTER
    });
  },

  twitterShare: function (copy) {
    var url = new URL('https://twitter.com/intent/tweet');
    if (copy) url.searchParams.append('text', copy);

    window.open(url, 'Twitter Share', 'width=550,height=420');
  },

  /**
 * [voteOnTwitterClick description]
 * @param  {[type]} e [description]
 * @return {[type]}   [description]
 */
  voteOnTwitterClick: function () {
    var copy = this.model.get('copy').buttons.twitter.text_template
      .replace('{{NAME}}', this.voteOption.hashtag)
      .replace('{{CATEGORY}}', this.categoryHashtag);
    this.twitterShare(copy);

    this.twitterVote();

    logShareEvent( ShareEvent.Network.Twitter );
  },

  /**
 * [shareToTwitterClick description]
 * @param  {[type]} e [description]
 * @return {[type]}   [description]
 */
  shareToTwitterClick: function() {
    var copy = this.model.get('copy').sharing.twitter
      .replace('{{NAME}}', this.voteOption.hashtag)
      .replace('{{CATEGORY}}', this.categoryHashtag);
    this.twitterShare(copy);

    this.twitterVote();

    logShareEvent( ShareEvent.Network.Twitter );
  },
  /**
     * [shareToFacebook description]
     * @param  {object} e - event object
     * @return {undefined}
     */
  shareToFacebookClick: function () {
    var voteOption = this.model.get('voteOption').toJSON();
    var shareCopy = this.model.get('shareCopy');

    var params = {
      id: voteOption.id,
      category_id: voteOption.category_id
    };

    if (shareCopy) {
      params.copy_wid = shareCopy
    }

    PubSub.trigger('facebookShare', params);

    PubSub.trigger('shareVote', {
      model: this.model.get('voteOption'),
      action_type: CONSTANTS.FACEBOOK
    });

    logShareEvent( ShareEvent.Network.Facebook );
  },

  toggleLabelClass: function(){
    this.$('.calLabel').toggleClass('checked');
  },
  /**
     *
     */
  remove: function () {
    if (this.CSSView) {
      this.CSSView.remove();
    }

    this.stopListening();
    this.off();

    Backbone.View.prototype.remove.call(this);
  }

});
