
function mergeRecursive(obj1, obj2) {

  for (var p in obj2) {
    try {
      if ( typeof(obj2[p]) === 'object' ) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p]);

      } else if (typeof(obj2[p]) === 'string' && obj2[p]) {
        obj1[p] = obj2[p];
      }

    } catch(e) {
      obj1[p] = obj2[p];

    }
  }

  return obj1;
}

module.exports = mergeRecursive;
