'use strict';
var template = require('./../templates/loading.ejs');
var BaseView = require('./../views/base');

module.exports = BaseView.extend({
  tagName: 'div',
  className: 'view-loading',
  /**
   *
   */
  render: function () {

    this.$el.html(template(this.model));
    return this;
  }

});
