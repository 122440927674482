module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<%- namespace %> { <%- customizations.header_display==='false'? 'display: none;' : '' %> <%- customizations.background_color.length>0? \"background-color: \"+customizations.background_color+\";\" : \"background-color: transparent;\" %> } <%- namespace %> .logo { <%- logo.image_mobile.length>0? ' background-image: url('+logo.image_mobile+'); ' : '' %> <%- customizations.background_size.length>0? \"background-size: \"+customizations.background_size+\";\" : \"cover;\" %> <%- customizations.background_repeat.length>0? \"background-repeat: \"+customizations.background_repeat+\";\" : \"no-repeat;\" %> <%- customizations.background_position.length>0? \"background-position: \"+customizations.background_position+\";\" : \"center;\" %> <%- customizations.logo_display==='false'? 'display: none;' : '' %> } @media (min-width: 720px) { <%- namespace %> .logo { <%- logo.image_tablet.length>0? ' background-image: url('+logo.image_tablet+'); ' : '' %> } } @media (min-width: 970px) { <%- namespace %> .logo { <%- logo.image.length>0? ' background-image: url('+logo.image+'); ' : '' %> } } <%- namespace %> .wrapper-text p { <%- customizations.paragraph_color.length>0? 'color: '+customizations.paragraph_color+';' : '' %> } @media (max-width: 319px) { <%- namespace %> .logo { <%- logo.image_widget.length>0? ' background-image: url('+logo.image_widget+'); ' : '' %> } } "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append( namespace )
    ; __append(" { ")
    ; __append( customizations.header_display==='false'? 'display: none;' : '' )
    ; __append(" ")
    ; __append( customizations.background_color.length>0? "background-color: "+customizations.background_color+";" : "background-color: transparent;" )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(" .logo { ")
    ; __append( logo.image_mobile.length>0? ' background-image: url('+logo.image_mobile+'); ' : '' )
    ; __append(" ")
    ; __append( customizations.background_size.length>0? "background-size: "+customizations.background_size+";" : "cover;" )
    ; __append(" ")
    ; __append( customizations.background_repeat.length>0? "background-repeat: "+customizations.background_repeat+";" : "no-repeat;" )
    ; __append(" ")
    ; __append( customizations.background_position.length>0? "background-position: "+customizations.background_position+";" : "center;" )
    ; __append(" ")
    ; __append( customizations.logo_display==='false'? 'display: none;' : '' )
    ; __append(" } @media (min-width: 720px) { ")
    ; __append( namespace )
    ; __append(" .logo { ")
    ; __append( logo.image_tablet.length>0? ' background-image: url('+logo.image_tablet+'); ' : '' )
    ; __append(" } } @media (min-width: 970px) { ")
    ; __append( namespace )
    ; __append(" .logo { ")
    ; __append( logo.image.length>0? ' background-image: url('+logo.image+'); ' : '' )
    ; __append(" } } ")
    ; __append( namespace )
    ; __append(" .wrapper-text p { ")
    ; __append( customizations.paragraph_color.length>0? 'color: '+customizations.paragraph_color+';' : '' )
    ; __append(" } @media (max-width: 319px) { ")
    ; __append( namespace )
    ; __append(" .logo { ")
    ; __append( logo.image_widget.length>0? ' background-image: url('+logo.image_widget+'); ' : '' )
    ; __append(" } } ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}