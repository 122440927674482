'use strict';

var CmsModel = require('@telescope/tscom-cms');
var Backbone = require('backbone');

module.exports = Backbone.Collection.extend({
  model: CmsModel,

  activeModel: null,

  getActive: function() {
    return this.activeModel;
  },

  setActive: function(wid, options) {
    var options = options || {};
    var model = this.get(wid);
    this.activeModel = model;

    if (!options.silent) {
      this.trigger('update:activeModel', model);
    }
  },

  resetActive: function(options) {
    var options = options || {};
    this.activeModel = null;

    if (!options.silent) {
      this.trigger('update:activeModel');
    }
  }
});
