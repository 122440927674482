'use strict';
var _ = require('lodash');
var Backbone = require('backbone');
var Model = require('./../models/vote');
var slugify = require('slugify');
var SORTING_METHOD = require("./../constants").SORTING_METHOD;
var constants = require('./../constants');

module.exports = Backbone.Collection.extend({
  model: Model,
  /**
     *
     */
  initialize: function (attrs, options) {
    this.sortMethod = options? options.sortMethod : null;
    this.listenTo(this, 'add', this.onModelAdded.bind(this));
  },

  onModelAdded: function(model) {
    var name = model.get('name');
    model.set('slug', slugify(name, {
      remove: constants.SLUGIFY.REGEX,
      lower: true
    }));
  },

  comparator: function (a, b) {
    switch(this.sortMethod) {

    case SORTING_METHOD.ALPHABETICAL:
      return a.get('name').toLowerCase().trim().localeCompare(b.get('name').toLowerCase().trim());
    case SORTING_METHOD.CUSTOM:
      return parseInt(a.get('order')) - parseInt(b.get('order'));
    default:
      return Math.floor(Math.random() * 3) - 1;

    }
  },
  /**
     * [next description]
     * @param  {[type]}   option [description]
     * @return {Function}        [description]
     */
  next: function (option) {
    var model = this.at(this.indexOf(option) + 1);

    if (_.isUndefined(model)) {
      model = this.last();
    }

    return model;
  },
  /**
     * [prev description]
     * @param  {[type]} option [description]
     * @return {[type]}        [description]
     */
  prev: function (option) {
    var model = this.at(this.indexOf(option) - 1);

    if (_.isUndefined(model)) {
      model = this.first();
    }

    return model;
  }
});
