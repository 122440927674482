'use strict';

var template = require('./../templates/geo.ejs');
var BaseView = require('./../views/base');

module.exports = BaseView.extend({
  tagName: 'div',
  className: 'view-geo',
  /**
     *
     */
  render: function () {

    this.$el.html(template(this.model));

    return this;
  }
});
