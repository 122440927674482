'use strict';

var _ = require("lodash");
var $ = require("jquery");
var Backbone = require("backbone");
var modelFacebook = require('@telescope/tscom-facebook');
var getQueryParamByName = require('tscom-util/src/getQueryParamByName');

var FB_CONST = require('./../constants').FACEBOOK;
var AUTH_CONST = require('./../constants').AUTH;

module.exports = modelFacebook.extend({
  defaults: _.extend(_.clone(modelFacebook.prototype.defaults), {
    isManualFlow: false
  }),
  /**
   *
   */
  initialize: function(attributes, options) {
    _.bindAll(this, 'updateLoginStatus', 'checkUserLoginStatus');

    options || (options = {});
    _.extend(this.options, options);

    if (typeof FB === 'object' && !_.isUndefined(window.fbAsyncInit)) {
      this.onScriptLoaded();
      return;
    } else {
      window.fbAsyncInit = this.onScriptLoaded.bind(this);
      this.loadScript(document, 'script', 'facebook-jssdk');
    }
  },
  /**
   *
   */
  loadScript: function (d, s, id) {
    var isProd = (window.location.host.substr(0, 5) === 'local' || window.location.host.indexOf('dev') !== -1) ? false : true;


    var isBeta = (getQueryParamByName('fb') === 'beta') ? true : false;


    var isDebug = (!isProd || isBeta) ? true : false;


    var js;


    var fjs = d.getElementsByTagName(s)[0];

    if (d.getElementById(id)) {
      return;
    }

    var src = 'https://connect' + (isBeta ? '.beta' : '') + '.facebook.com/en_US/sdk' + (isDebug ? '/debug' : '') + '.js';

    js = d.createElement(s);
    js.id = id;
    js.className = 'optanon-category-2';
    js.src = src;
    fjs.parentNode.insertBefore(js, fjs);
  },
  /**
   *
   */
  loadedScript: function () {
    /**
     * After loading the JavaScript SDK, call FB.init to initialize the SDK with your app ID.
     * This will let you make calls against the Facebook API.
     */
    FB.init({
      'appId': this.get('appId'),      // App ID from the app dashboard
      'status': true,                  // Check Facebook Login status
      'version': FB_CONST.VERSION,
      'cookie': false
    });
    this.set('scriptLoaded', true);

    this.subscribeToEvents();

    /**
     * Check User Login Status when they open this app the first time
     */
    this.checkUserLoginStatus();

  },
  /**
   *
   * overwritten to include check for manual flow
   *
   */
  share: function (url, callback) {

    callback = callback || _.noop;
    if (this.get('isManualFlow')) {
      this.manualShare(url);
      return;
    }

    FB.ui({
      method: 'share',
      display: 'popup',
      href: url
    }, this.shareResponse.bind(this, callback));

  },
  /**
   * [feedShare description]
   * @param  {[type]}   params   [description]
   * @param  {Function} callback [description]
   * @return {[type]}            [description]
   */
  feedShare: function (params, callback) {
    callback = callback || _.noop;

    FB.ui(_.extend({
      method: 'feed',
      display: 'popup'
    }, params), this.shareResponse.bind(this, callback));
  },
  /**
   * callback for share()
   */
  shareResponse: function(cb){
    if(typeof cb === 'function'){
      cb();
    }
  },
  /**
   *
   * overwritten to check if the user is conected before attempting to invoke the FB.logout method
   *
   */
  logout: function (callback) {
    callback = callback || _.noop;
    var checkUserLoginStatus = this.checkUserLoginStatus;

    FB.logout(function () {
      checkUserLoginStatus();
      callback();
    });
  },
  /**
   * overwritten to accept a callback funtion
   *
   */
  checkUserLoginStatus: _.debounce(function (callback) {
    callback = callback || _.noop;

    FB.getLoginStatus(function (response) {

      callback(response);

      if (response.status === 'connected') {
        this.set({
          authResponse: response.authResponse,
          status: response.status
        });

      } else if (response.status === 'not_authorized') {
        // user is logged in, but not authorize this app, use FB.login() to make them auth.
        this.set({
          authResponse: 'not_authorized',
          status: response.status
        });

      } else { // response.status === 'unknown'
        this.set({
          authResponse: 'unknown',
          status: response.status
        });

      }

      this.recentlyLoggedIn = false;

    }.bind(this), true);
  }, 1000, true),
  /**
   * overwritten to compensate for errors
   *
   */
  setUserDataInSync: _.debounce(function () {

    var sessionActive = this.get('status') === 'connected' && _.isObject(this.get('authResponse')) && !_.isUndefined(this.get('authResponse').userID);

    if (sessionActive) {

      var path = '/' + this.get('authResponse').userID + '?fields=id,first_name,last_name,name,locale,permissions,verified,email';
      FB.api(path, this.saveUserData.bind(this, _.noop), true);

    } else {

      this.clearUserData();

    }

  }, 1000, true),
  /**
   * Save User Graph Data to this model
   * @param {[type]} response [description]
   */
  saveUserData: function (callback, response) {
    this.recentlyLoggedIn = true;

    callback = (callback && _.isFunction(callback) ? callback : _.noop);

    if (!_.isUndefined(response.error)) {
      console.error('the request for user data failed')
      return false;
    }
    var permissions = (!_.isObject(response.permissions.data[0]) ? {} : response.permissions.data);


    var publishAction = _.find(permissions, { 'permission': 'publish_actions' })
      ;

    this.set({
      user: _.extend(response, {
        profile_image: 'http://graph.facebook.com/' + response.id + '/picture?type=large',
        permissions: (!_.isObject(response.permissions.data[0]) ? {} : response.permissions.data[0]),
      }),
      timelineSharing: (_.isObject(publishAction) && publishAction.status === 'granted') ? 1 : 0
    });

    this.set('callback', null);
    callback();
  },
  /**
   * overwritten to handle timeline permission cleanup
   */
  clearUserData: function () {
    this.set({
      user: null,
      timelineSharing: 0
    })
  },
  /**
   *
   */
  manualRedirectURI: function () {
    var redirectURI = (top !== self ? document.referrer : window.location.href).split('#')[0].replace(/(.*\?).*/g, '$1');
    var qspWhitelist = {
      app: (_.contains(FB_CONST.MANUAL_LOGIN_DEVICES, getQueryParamByName('app')) ? getQueryParamByName('app') : '')
    };

    _.each(qspWhitelist, function (value, key) {
      qspWhitelist = (value.length === 0) ? _.omit(qspWhitelist, key) : qspWhitelist;
    });

    return redirectURI + (redirectURI.charAt(redirectURI.length - 1) === '?' ? '' : '?') + $.param(qspWhitelist);
  },
  /**
   *
   */
  manualLogin: function () {
    var params = $.param({
      client_id: this.get('appId'),
      response_type: 'token',
      scope: { scope: this.get('scope') },
      redirect_uri: this.manualRedirectURI(),
      // auth_type: 'rerequest',
      state: encodeURI(Backbone.history.getFragment())
      // encodeURIComponent - decodeURIComponent
    });

    window.top.location = encodeURI(FB_CONST.OAUTH_URI) + params;
  },
  /**
   *
   * @param url
   */
  manualShare: function (url) {
    var params = $.param({
      app_id: this.get('appId'),
      display: FB_CONST.POPUP,
      href: url
    });

    window.open(encodeURI(FB_CONST.SHARE_URI) + params, 'Facebook Share', 'width=550,height=420');
  },
  /**
   * Prompt the user to grant permissions
   */
  requestPermissions: function () {
    this.login({ auth_type: AUTH_CONST.AUTH_TYPE.REREQUEST });
  },
  /**
   * Check if a user has granted email permissions
   * @return {Boolean}
   */
  hasFbEmailPermission: function() {
    const permissions = this.get('permissions') || [];
    return permissions.indexOf('email') > -1;
  },
  /**
   *
   */
  setUserDataWithToken: function () {
    if (!this.get('scriptLoaded')) {
      // once we know the SDK has loaded we can init the Router
      this.listenToOnce(this, 'change:scriptLoaded', this.setUserDataWithToken.bind(this));
      return false;
    }

    FB.api('/me', {
      fields: 'id,first_name,last_name,name,email,locale,permissions,verified',
      access_token: this.get('accessToken')
    }, function (response) {
      if (!response || response.error) {
        //error
        return;
      } else if (!response.email) {
        this.set('user', _.extend(response, {
          profile_image: FB_CONST.GRAPH_URI + response.id + '/picture?type=large',
          permissions: (!_.isObject(response.permissions.data[0]) ? {} : response.permissions.data[0]),
          email: ""
        }));
        return;
      } else {
        this.set('user', _.extend(response, {
          profile_image: FB_CONST.GRAPH_URI + response.id + '/picture?type=large',
          permissions: (!_.isObject(response.permissions.data[0]) ? {} : response.permissions.data[0])
        }));
        return;
      }
    }.bind(this));

  }
});
