'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');

Backbone.$ = $;

// localStorage
Backbone.LocalStorage = require('backbone.localstorage');

// relative utils
require('./util/plugin');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/button');

// validation
var validation = require('backbone-validation');
_.extend(Backbone.Model.prototype, validation.mixin);
_.extend(Backbone.Validation.patterns, {
  alphanumeric: /^[a-zA-Z0-9]+$/,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  word: /^\w+$/,
  age: /^1[0-9][0-9]$|^[1-9][0-9]$|^[1-9]$/,
  name: /^[\u00C0-\u017E a-zA-Z\']+$/
});
_.extend(Backbone.Validation.messages, {
  alphanumeric: 'Letter and number characters required.',
  phone: 'A valid phone number is required. e.g. (000)000-0000',
  word: 'Word required.',
  age: 'A valid human age is required',
  name: 'A valid name is required'
});


// shimmed modules
require('jquery-touchswipe');

_.noConflict();
$.noConflict(true);
Backbone.noConflict();


var widget = require('./Widget');
var WidgetClassName = 'telescope-pcas';


/**
 *
 * @param options
 * {
 * widget_id: 'data/data.json' // REQUIRED string
 * container: $('#myWidget') // REQUIRED string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * modal: $('#wrapper') // string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * uniqueId: 'name-type-namespace-rev' // string
 * cssUrl: 'http://a1.telesocpe.tv/styles/main.css' // absolute path
 * cssIsUnique: true // boolean - if true css should use an ID selector for the namespacing, otherwise use a class selector for the namespacing and restrict loading and injection of style block to a single execution
 * hashState: false // boolean - when true, enables hashable routes **for testing - only set to true when instantiation of the widget is limited to one object per frameElement/window
 * endpoints: {cms: 'https://widgetstate.votenow.tv/v1/state/''} // object
 * }
 */
module.exports = function (options) {

  // no hashed routes on initialize
  //if(window.location.href.indexOf('#') !== -1 && window.location.search.indexOf('devmode')===-1){
  //    window.location = window.location.href.split('#')[0];
  //}
  var options = options || {};

  options.container = $(options.container || document.body);
  options.modal = $(options.modal || options.container);

  options.cssIsUnique = (typeof options.cssIsUnique === 'undefined' ? true : options.cssIsUnique);

  options.uniqueId = options.uniqueId
        || $(options.container).attr("id")
        || (function () {
          var retval = WidgetClassName;
          if (options.widget_id) {
            retval += "-" + options.widget_id + "-";
          }
          retval += (Date.now() % 1e6);
          return retval;
        }());

  if (!$(options.uniqueId).length) {
    $(options.container).attr('id', options.uniqueId);
  }

  widget(options);
};
