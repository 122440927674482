'use strict';
var _ = require("lodash");
var $ = require("jquery");
var Backbone = require("backbone");
var PubSub = require('tscom-pubsub');
var TLDRC = require('tldr-child');

var loader = require('./../util/module-loader');
var viewLandingLoader = loader(() => import('./../views/landing'));
var viewWindowLoader = loader(() => import('./../views/window'));
var modelWindowLoader = loader(() => import('./../models/window'));
var viewAuthLoader = loader(() => import('./../views/auth'));
var modelAuthViewLoader = loader(() => import('./../models/auth-view'));
var viewErrorLoader = loader(() => import('./../views/error'));
var viewGridLoader = loader(() => import('./../views/grid'));
var viewVoteDetailLoader = loader(() => import('./../views/vote-detail'));

var ModelVoteOption = require('./../models/vote');
var ViewThanks = require('./../views/thanks');
var ViewModal = require('./../views/modal');
var ViewLoading = require('./../views/loading');
var ViewPage = require('./../views/page');
var ViewIframe = require('./../views/iframe');
var ViewGeo = require('./../views/geo');

var CSSView = require('./../views/css');
var WindowlessRouter = require('tscom-util/src/windowlessrouter');
var ModelBase = require("./../models/base");

var templateCss = require('./../templates/css.ejs');

var getQueryParamByName = require('tscom-util/src/getQueryParamByName');

var CONSTANTS = require('./../constants');
var APP_STATE = CONSTANTS.APP_STATE;
var ROUTES = CONSTANTS.ROUTES;
const { trackPageView, trackCustomEvent } = require("../util/ga4");
const { logPageLoadEvent } = require("../util/mparticle");
var isTrue = require("./../util/helpers").isTrue;

module.exports = function routerConstructor(options) {

  var hashState = options.hashState;
  var Router = (!hashState) ? WindowlessRouter : Backbone.Router;

  return Router.extend({
    controller: null,
    currentView: null,
    currentModal: null,
    routes: {
      '': 'landing',
      'landing': 'landing',
      'thanks/:category(/:nominee)': 'thanks',
      'vote/:category/:nominee': 'voteDetail',
      'out-of-region': 'geo',
      'auth(/:source)': 'auth',
      'error/:name': 'error',
      'loading/:name': 'loading',
      'window': 'window',
      'terms': 'terms',
      'smsterms': 'smsTerms',
      'faq': 'faq',
      'rules': 'rules',
      'photo_video_info': 'info',
      ':category': 'category',
      ':group(/:category)': 'categoryGroup'
    },
    history: [],
    /**
     *
     */
    initialize: function (options) {
      if (_.isUndefined(options)) {
        return;
      }

      this.controller = options.controller;
      this.instanceID = $(options.el || document.body).attr('id');
      this.uid = options.uid;

      this.route(/access_token=(.*?)$/, 'manualOAuth');

      //pym child init
      this.pym = TLDRC.Child({ polling: 500 });
      this.pymChildId = getQueryParamByName('childId') || "";

      this.isFirstPage = true;

      this.listenTo(PubSub, 'login', this.auth.bind(this));

      this.listenTo(PubSub, 'navigate', function (route) {
        this.navigate(route, { trigger: true })
      }, this)

      this.listenTo(PubSub, '_back', function () {
        this._back();
      }, this)

      this.listenTo(PubSub, 'closeModal', this.closeCurrentModal.bind(this));
      this.listenTo(PubSub, 'userCloseModal', this.closeCurrentModal.bind(this));

      this.listenTo(PubSub, 'AnimationFrame', this.calculateLayout.bind(this));
      this.listenTo(PubSub, 'trackClick', this.handleViewEvent.bind(this));

      this.listenTo(this, 'route', this._saveHistory.bind(this));

      this.page = new ViewPage({
        model: this.controller.Models.Page,
        ads: this.controller.Models.Cms.get('text').ads,
        geo: this.controller.Models.Geo
      }).render();

      this.$body = $(options.el || document.body)
      this.$modalScope = $(options.el || document.body)
      this.$app = this.page.$('.main-content');
      this.$modal = $('<div class="modal"' + ((_.isEqual(options.container, options.modal) ? '' : ' id="' + options.uid + 'Modal"')) + ' />');

      this.$body.html('').append(this.page.$el);
      this.$modalScope.append(this.$modal);

      $(document).on('click', 'input[type="button"],input[type="submit"],select,button,a', this.handleViewEvent.bind(this));

      this.css()

      var animationFrameHandler = function (timestamp) {
        PubSub.trigger('AnimationFrame', timestamp);
        window.requestAnimationFrame(animationFrameHandler);
      }.bind(this);
      window.requestAnimationFrame(animationFrameHandler);
    },
    /**
     *
     */
    setCurrentView: function (view, tracking) {

      this.closeCurrentModal();
      this.$app.html(view.render().$el);

      var bodyContext = 'body-' + view.className;
      this.$app.alterClass('body-*', bodyContext);
      this.$body.alterClass('body-*', bodyContext);

      // remove old view
      if (this.currentView !== null) {
        this.currentView.remove();
        this.currentView = null;
      }
      // store current view
      this.currentView = view;

      if (typeof this.currentView.renderedToDom === 'function') {
        this.currentView.renderedToDom();
      }

      if (_.isString(tracking)) {
        this.handlePageView(tracking, view);
      }

      if([ROUTES.TERMS, ROUTES.SMS, ROUTES.FAQ, ROUTES.RULES].includes(tracking)) {
        return this.scrollToTop();
      }

      PubSub.trigger('renderMainView', tracking)
      this.scrollToTop();
      PubSub.trigger("current-view-set");
    },
    /**
     *
     */
    setCurrentModal: async function (view, tracking) {
      var prevRoute = null;

      if (this.currentView == null) {
        if (typeof view.id == 'undefined') {
          this.navigate(ROUTES.LANDING, { trigger: true });
          return;
        }
        else if (view.id.indexOf('vote-detail') !== 0 || view.id.indexOf('thanks') !== 0) {
          var { route } = view.options;
          var routeOption = route.split(":")[3]? `/${route.split(":")[3]}` : '';
          prevRoute = `${route.split(":")[0]}/${route.split(":")[2]}${routeOption}`;
          this.listenToOnce(PubSub, 'current-view-set', function () {
            this.navigate(prevRoute, { trigger: true });
          }.bind(this));

          this.votingModalPrevRoute = `${route.split(":")[1]}/${route.split(":")[2]}`;
          this.navigate(`${route.split(":")[1]}/${route.split(":")[2]}`, { trigger: true });

          return;
        } else {

          prevRoute = view.id.split(":")[0] + "/" + view.id.split(":")[1];
          this.listenToOnce(PubSub, 'current-view-set', function () {
            this.navigate(prevRoute, { trigger: true });
          }.bind(this));
          this.navigate(ROUTES.LANDING, { trigger: true });

          return;
        }
      } else if (view.id && (view.id.indexOf('vote-detail') === 0 || view.id.indexOf('thanks') === 0)) {
        var { route } = view.options;
        this.votingModalPrevRoute = `${route.split(":")[1]}/${route.split(":")[2]}`;
      }

      var modalContext = 'modal-has-' + view.className;
      var modal = new ViewModal({
        model: { view: view },
        controller: this.controller
      });

      this.page.$el.alterClass('modal-has-*', modalContext);
      modal.$el.alterClass('modal-has-*', modalContext);
      this.$modal.html(modal.render().$el);

      //PCA-623:
      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      var instagram = /Instagram/.test(navigator.userAgent);

      if( !iOS && !instagram ) {
        $('html').addClass('no-scroll');
      }

      $(document.body).addClass('modal-open');
      this.$modalScope.css('overflow-y', 'hidden');
      // remove old modal
      if (this.currentModal !== null) {
        if (_.isObject(this.currentModal.model.view)) {
          this.currentModal.model.view.remove();
        }
        this.currentModal.remove();
        this.currentModal = this.controller.Views.Modal = null;
      }
      // store current modal
      this.currentModal = this.controller.Views.Modal = modal;

      if (typeof this.currentModal.renderedToDom === 'function') {
        this.currentModal.renderedToDom();
      }

      if (typeof this.currentModal.model.view.renderedToDom === 'function') {
        this.currentModal.model.view.renderedToDom();
      }


      if (_.isString(tracking)) {
        this.handlePageView(tracking, this.currentModal.model.view);
      }

      if (typeof view.renderedToDom === 'function') {
        view.renderedToDom();
      }

      document.activeElement.blur()
      this.scrollToTop();
    },
    /**
     *
     */
    closedCurrentModal: function () {
      if (this.currentModal !== null) {
        if (_.isObject(this.currentModal.model.view)) {
          this.currentModal.model.view.remove();
        }
        this.currentModal.remove();
        this.currentModal = this.controller.Views.Modal = null;
        this.page.$el.alterClass('modal-has-*', '');
        $('html').removeClass('no-scroll');
        this.$modalScope.css('overflow-y', 'auto');
        PubSub.trigger('enableVote');

        if (this.votingModalPrevRoute) {
          PubSub.trigger('navigate', this.votingModalPrevRoute);
          this.votingModalPrevRoute = null;
        }
      }
    },
    /**
     *
     */
    closeCurrentModal: function () {
      this.closedCurrentModal();
      $(document.body).removeClass('modal-open');
    },
    /**
     * [css description]
     * @return {[type]} [description]
     */
    css: function (model) {
      model = model || this.controller.Models.Cms.get('text')['global_customizations'] || {
        "font_family": "FacitWeb",
        "font_import": "https://assets-us.votenow.tv/fonts/facit/FacitWeb.css"
      };

      this.CSSView = new CSSView({
        model: model,
        template: templateCss
      });

      Backbone.$('head').append(this.CSSView.render().$el);
    },
    /**
     * 
     */
    geo: async function () {
      const copy = this.controller.getActiveLanguageCopy();
     
      var view = new ViewGeo({
        model: copy.views_error.geo
      });

      logPageLoadEvent('Vote Out of Region');

      this.setCurrentView(view, 'out-of-region');
    },
    /**
     *
     */
    window: async function () {
      if (this._isOpen(ROUTES.WINDOW)) {
        this.navigate(ROUTES.LANDING, { trigger: true });
        return;
      }
      const ViewWindow = await viewWindowLoader();
      const ModelWindow = await modelWindowLoader();

      var cmsCopy = this.controller.getActiveLanguageCopy();

      var countdown = new Date(cmsCopy['view_window'].countdown.future_time).getTime();

      if (this.controller.Models.Window) {
        this.controller.Models.Window.destroy();
        this.controller.Models.Window = null;
      }
      var model = this.controller.Models.Window = new ModelWindow({
        copy: _.clone(cmsCopy.view_window, true),
        uid: this.uid,
        countdown: _.extend({
          future_time: parseInt(countdown, 10),
          server_time: Date.now(),
          labels: cmsCopy['view_window'].countdown.labels,
        }, _.clone(cmsCopy['view_window'].countdown.units, true))
      }, { textKey: 'view_window', cms: this.controller.Models.Cms });
      var view = new ViewWindow({
        ads: this.controller.Models.Cms.get('text').ads,
        model: model
      });


      logPageLoadEvent('Vote Closed');

      this.setCurrentView(view, 'window-closed');
    },
    /**
     *
     */
    landing: async function () {
      if (!this._isOpen(ROUTES.LANDING)) {
        return;
      }

      const ViewLanding = await viewLandingLoader();

      if (this.controller.Models.Landing) {
        this.controller.Models.Landing.destroy();
        this.controller.Models.Landing = null;
      }

      var cmsCopy = this.controller.getActiveLanguageCopy();
      await this.controller.clearCategory();

      var modelNav = this.controller.Models.Nav = new ModelBase({
        uid: this.uid,
        groups: this.controller.Collections.CategoryGroup.models,
        categoryGroupCollection: this.controller.Collections.CategoryGroup
      }, { textKey: 'view_nav', cms: this.controller.Models.Cms });

      var model = this.controller.Models.Landing = new ModelBase({
        copy: _.clone(cmsCopy.view_landing, true),
        uid: this.uid,
        user: this.controller.Models.User,
        categoryGroupCollection: this.controller.Collections.CategoryGroup,
        region: this.controller.appRegion,
        isWidget: this.controller.isWidget,
        modelNav: modelNav,
        optins: this.controller.Models.Auth.get('optins')
      }, { textKey: 'view_landing', cms: this.controller.Models.Cms });
      var view = new ViewLanding({
        model: model,
        ads: this.controller.Models.Cms.get('text').ads
      });

      logPageLoadEvent('Homepage');

      this.setCurrentView(view, ROUTES.LANDING);
    },

    category: async function( categorySlug ) {
      if (!this._isOpen(`/${categorySlug}`)) {
        return;
      }

      const catGroup = this.controller.Collections.CategoryGroup;
      
      if( catGroup.length > 1 ) {
        this.categoryGroup( categorySlug );
        return;
      }

      const matchingGroup = catGroup.at(0);
      const matchingCategory = catGroup.getCategoryBySlug( matchingGroup.id, categorySlug );

      if( matchingCategory.get('slug') !== categorySlug ) {
        PubSub.trigger('navigate', '');
        return;
      }

      if (this.votingModalPrevRoute) {
        this.votingModalPrevRoute = `${categorySlug}`
      }

      this._renderCategory( categorySlug, matchingGroup, matchingCategory );
    },

    _renderCategory: async function( categorySlug, matchingGroup, matchingCategory ) {
      const ViewGrid = await viewGridLoader();
      const catGroup = this.controller.Collections.CategoryGroup;

      await this.controller.loadLanguageCmsModel(matchingGroup.get('language_wid'));

      var cmsCopy = this.controller.getActiveLanguageCopy();
      var cmsData = this.controller.getActiveLanguageGroups();

      PubSub.trigger('categorySelected', matchingCategory);

      var activeCategoryGroupId = matchingGroup.id;

      // trash the vote model if it was previously defined
      if (this.controller.Models.Vote) {
        this.controller.Models.Vote.destroy();
        this.controller.Models.Vote = null;
      }

      if (this.controller.Models.Nav) {
        this.controller.Models.Nav.destroy();
        this.controller.Models.Nav = null;
      }

      var modelNav = this.controller.Models.Nav = new ModelBase({
        copy: _.clone(cmsCopy.view_nav, true),
        uid: this.uid,
        activeCategory: matchingCategory,
        activeCategoryGroupId: activeCategoryGroupId,
        activeCategoryGroupSlug: matchingGroup.get('slug'),
        groups: cmsData,
        categoryGroupCollection: catGroup,
        mobileBreakpoint: (this.$body.width() < 750),
        navCatGroups: false,
        menuCopy: cmsCopy.view_menu
      }, { textKey: 'view_nav', cms: this.controller.Models.Cms });

      this.controller.currentCatId = categorySlug;
      var copy = _.clone(cmsCopy.view_vote, true);

      await this.controller.fetchCategory(matchingCategory.id);

      // define our data
      var modelVote = this.controller.Models.Vote = new ModelBase({
        copy: copy,
        uid: this.uid,
        user: this.controller.Models.User,
        category: categorySlug,
        categoryCollection: matchingCategory || _.sample(this.controller.Collections.CategoryGroup.at(0).get('categoryCollection').models),
        categoryGroupCollection: this.controller.Collections.CategoryGroup,
        activeCategoryGroupId: activeCategoryGroupId,
        modelNav: modelNav
      }, { textKey: 'view_vote', cms: this.controller.Models.Cms });

      // define our view
      var view = new ViewGrid({
        model: modelVote,
        ads: this.controller.Models.Cms.get('text').ads,
        auth: this.controller.Models.Auth,
        appState: this.controller.appState,
        isWidget: this.controller.isWidget,
        isMainPhase: isTrue(this.controller.Models.Cms.get("text").app_settings.use_main_key)
      });

      switch( this.controller.appState ) {
      case APP_STATE.OPEN:
        logPageLoadEvent('Vote Grid - ' + matchingCategory.get('name') );
        break;
      case APP_STATE.VOTING_CLOSED:
        logPageLoadEvent('Vote Ballot Closed');
        break;
      case APP_STATE.WINNERS:
        logPageLoadEvent('Vote Ballot Winner');
        break;
      }

      this.setCurrentView(view, 'vote-' + categorySlug);
    },

    /**
     *
     */
    categoryGroup: async function (group, category) {
      if (!this._isOpen(`/${group}/${category}`)) {
        return;
      }

      var catGroup = this.controller.Collections.CategoryGroup;
      var matchingGroup = catGroup.getGroupBySlug(group);

      if (!matchingGroup) {
        PubSub.trigger('navigate', '');
        return;
      }

      if (!category) {
        category = matchingGroup.get('categoryCollection').models[0].get('slug');
        window.history.replaceState(null,'',`${group}/${category}`);
      }

      // find the category model inside of Collections.CategoryGroup
      var matchingCategory = catGroup.getCategoryBySlug(matchingGroup.id, category);

      if (!matchingCategory) {
        PubSub.trigger('navigate', '');
        return;
      }

      if (this.votingModalPrevRoute) {
        this.votingModalPrevRoute = `${group}/${category}`
      }

      this._renderCategory( category, matchingGroup, matchingCategory );
    },

    /**
     * [voteDetail description]
     * @param  {[type]} id [description]
     * @return {[type]}    [description]
     */
    voteDetail: async function (category, nominee) {
      if (!this._isOpen(`vote-detail/${category}/${nominee}`)) {
        return;
      }
      const ViewVoteDetail = await viewVoteDetailLoader();

      var catGroup = this.controller.Collections.CategoryGroup;

      var matchingOption = this.controller.Collections.CategoryGroup.getOptionByCatAndNominee(category, nominee, 'slug');
      var matchingCategory = this.controller.Collections.CategoryGroup.getCategoryBySlug(null, category);
      var matchingGroup = catGroup.getGroupByCategory(matchingCategory);

      if (!matchingOption || !matchingCategory) {
        PubSub.trigger('navigate', '');
        return;
      }

      if (this.controller.Models.VoteDetail) {
        this.controller.Models.VoteDetail.destroy();
        this.controller.Models.VoteDetail = null;
      }

      var cmsCopy = this.controller.getActiveLanguageCopy();

      // define our data
      var model = this.controller.Models.VoteDetail = new ModelBase({
        copy: _.clone(cmsCopy.view_vote_detail, true),
        uid: this.uid,
        appState: this.controller.appState,
        voteOption: matchingOption,
        categoryCollection: matchingCategory,
        categoryGroupCollection: this.controller.Collections.CategoryGroup,
        voteLimit: this.controller.Models.Cms.get('settings').vote_limit,
        isWidget: this.controller.isWidget,
        user: this.controller.Models.User
      }, { textKey: 'view_vote_detail', cms: this.controller.Models.Cms });
      // define our view
      var view = new ViewVoteDetail({
        model: model,
        id: `vote-detail-${matchingCategory.get('id')}-${matchingOption.get('id')}`,
        route: `vote:${matchingGroup.get('slug')}:${category}:${nominee}`
      });
      
      logPageLoadEvent('Vote Allocation');

      this.setCurrentModal(view, 'voteDetail');

    },
    /**
     * [thanks description]
     * @param  {[type]} id [description]
     * @return {[type]}    [description]
     */
    thanks: function (category, nominee) {
      if (!this._isOpen(`thanks/${category}/${nominee}`) || !this._isOpen(`thanks/${category}`)) {
        return;
      }

      var catGroup = this.controller.Collections.CategoryGroup;
      var matchingOption = this.controller.Collections.CategoryGroup.getOptionByCatAndNominee(category, nominee, 'slug');
      var matchingCategory = this.controller.Collections.CategoryGroup.getCategoryBySlug(null, category);
      var matchingGroup = catGroup.getGroupByCategory(matchingCategory);

      if (!matchingOption) {
        //writeIn option
        matchingOption = new ModelVoteOption({
          id: CONSTANTS.WRITE_IN.CONTESTANT_ID,
          category_id: matchingCategory.get('id'),
          name: this.controller.writeInName,
          firstname: this.controller.writeInName,
          lastname: '',
          active: '1'
        }, {});
      }

      if (this.controller.Models.Thanks) {
        this.controller.Models.Thanks.destroy();
        this.controller.Models.Thanks = null;
      }

      var cmsCopy = this.controller.getActiveLanguageCopy();

      var isLocationUS = this.controller.Models.Geo.get('country').toLowerCase() === CONSTANTS.APP_REGION.US;

      // define our data
      var model = this.controller.Models.Thanks = new ModelBase({
        copy: cmsCopy.view_thanks,
        uid: this.uid,
        voteOption: matchingOption,
        categoryGroupCollection: this.controller.Collections.CategoryGroup,
        category: category,
        group: matchingGroup.get('slug'),
        shareCopy: matchingGroup.get('language_wid')
      }, { textKey: 'view_thanks', cms: this.controller.Models.Cms });
      // define our view

      var routeOption = nominee? `:${nominee}` : '';

      var view = new ViewThanks({
        cmsSettings: this.controller.Models.Cms.get('settings'),
        model: model,
        id: `thanks:${matchingCategory.get('id')}-${matchingOption.get('id')}`,
        route: `thanks:${matchingGroup.get('slug')}:${category}${routeOption}`,
        isLocationUS: isLocationUS
      });

      logPageLoadEvent('Vote Confirmation');

      this.setCurrentModal(view, 'thanks');
    },
    /**
     * [auth description]
     * @return {[type]} [description]
     */
    auth: async function (source) {
      source = source || '';
      if (!this._isOpen('auth')) {
        return;
      }

      const ViewAuth = await viewAuthLoader();
      const ModelAuthView = await modelAuthViewLoader();

      if (this.controller.Models.AuthView) {
        this.controller.Models.AuthView.destroy();
        this.controller.Models.AuthView = null;
      }

      var cmsCopy = this.controller.getActiveLanguageCopy();

      // define our data
      var model = this.controller.Models.AuthView = new ModelAuthView({
        id: this.controller.Models.Cms.get('wid'),
        copy: _.clone(cmsCopy.view_auth, true),
        uid: this.uid,
        model: this.controller.Models.Auth,
        devmode: this.controller.devmode,
        source: source
      }, { textKey: 'view_auth', cms: this.controller.Models.Cms });

      // define our view
      var view = new ViewAuth({
        model: model,
        fbModel: this.controller.Models.Facebook
      });


      logPageLoadEvent('Vote Login');

      this.setCurrentModal(view, 'auth');
    },
    /**
     *
     */
    error: async function (name) {
      const ViewError = await viewErrorLoader();

      var cmsCopy = this.controller.getActiveLanguageCopy();

      var data = cmsCopy['views_error'][name];
      data = (_.isObject(data) ? data : cmsCopy['views_error']['generic']);
      var customizations = cmsCopy['views_error']['customizations'];

      var view = new ViewError({
        model: data,
        className: 'view-error-' + name,
        controller: this.controller,
        customizations: customizations,
        uid: this.uid,
        id: "error:" + name
      });

      var tracking = (name === ROUTES.OVERLIMIT)? ROUTES.OVERLIMIT: null;

      switch( name ) {
      case ROUTES.OVERLIMIT:
        logPageLoadEvent('Overlimit');
        break;
      case ROUTES.GEO:
        logPageLoadEvent('Vote Out of Region');
        break;
      default:
        logPageLoadEvent('Generic Error');
        break;
      }
      this.setCurrentModal(view, tracking);
    },
    /**
     *
     */
    loading: function (name) {
      var cmsCopy = this.controller.getActiveLanguageCopy();

      var data = cmsCopy['views_loading'][name];
      data = (_.isObject(data) ? data : cmsCopy['views_loading']['generic']);
      var view = new ViewLoading({
        model: data,
        className: 'view-loading-' + name,
        controller: this.controller,
        id: "loading:" + name
      });
      this.setCurrentModal(view);
    },
    /**
     *
     */
    terms: function() {
      var termsURL = this.controller.Models.Cms.get('settings').terms_url;
      var view = new ViewIframe({
        model: {
          url: termsURL,
          id: 'ts-pca-terms'
        }
      });

      this.setCurrentView(view, ROUTES.TERMS);
    },
    /**
     *
     */
    smsTerms: function() {
      var smsTermsURL = this.controller.Models.Cms.get('settings').sms_terms_url;
      var view = new ViewIframe({
        model: {
          url: smsTermsURL,
          id: 'ts-pca-sms-terms'
        }
      });

      this.setCurrentView(view, ROUTES.SMS);
    },
    /**
     *
     */
    faq: function() {
      var faqURL = this.controller.Models.Cms.get('settings').faq_url;
      var view = new ViewIframe({
        model: {
          url: faqURL,
          id: 'ts-pca-faq'
        }
      });

      logPageLoadEvent('Vote FAQ');

      this.setCurrentView(view, ROUTES.FAQ);
    },
    /**
     *
     */
    rules: function() {
      var rulesURL = this.controller.Models.Cms.get('settings').rules_url;
      var view = new ViewIframe({
        model: {
          url: rulesURL,
          id: 'ts-pca-rules'
        }
      });

      logPageLoadEvent('Vote Rules');

      this.setCurrentView(view, ROUTES.RULES);
    },
    /**
     *
     */
    info: function() {
      var pageURL = this.controller.Models.Cms.get('settings').photo_video_info;
      var view = new ViewIframe({
        model: {
          url: pageURL,
          id: 'ts-pca-info'
        }
      });

      this.setCurrentView(view, ROUTES.INFO);
    },
    /**
     *
     */
    _isOpen: function (requestedFragment) {
      // 1. If we are in one of the allowed regions, AND the window
      //    is open, immedately return true.  In all other cases,
      //    return false.
      // 2. If the region data has not returned yet, CHANGE the url
      //    to show loading, then once the region is available, CHANGE
      //    the url back to what it was at the time of the call
      // 3. If the region data has returned, but is not valid, CHANGE
      //    the url show out-of-geo
      // 4. If the region data has returned and is good, but the window
      //    is not open, CHANGE the url to the closed window page
      requestedFragment = requestedFragment || 'home';

      // is the user out of the geographically acceptable region
      if (_.isNull(this.controller.Models.Geo.get('inRegion'))) {
        this.listenToOnce(this.controller.Models.Geo, 'change:inRegion', function () {
          this.navigate(requestedFragment, { trigger: true })
        }.bind(this));
        this.navigate('loading/generic', { trigger: true });
        return false;
      } else if (!this.controller.Models.Geo.get('inRegion')) {
        this.navigate('out-of-region', { trigger: true })
        return false;
      }

      // is the window closed?
      if (parseInt(this.controller.Models.Cms.get('settings').window_status, 10) === 0 && this.controller.appState === APP_STATE.COUNTDOWN_CLOSED) {
        this.navigate('window', { trigger: true })
        return false;
      }

      return true;
    },
    /**
     *
     */
    _saveHistory: function (route, params) {
      this.history.unshift({
        route: route,
        params: params
      });
    },
    /**
     *
     */
    _back: function () {
      if (!hashState) {
        var previous = (this.history[0] || {});
        var route = (previous.route || ROUTES.LANDING);
        var params = (previous.params || []);

        this[route].apply(this, params);
      } else {
        window.history.back()
      }
    },
    /**
     *
     * @param route
     * @private
     */
    handlePageView: function (route) {
      if ( this.controller.googleEnabled ) {
        trackPageView( route, route );
      }
    },
    /**
     *
     * @param e
     * @param eventName
     */
    handleViewEvent: function (e, eventName) {
      var category = '';
      var action = '';
      var tag = (_.isString(e) ? '' : e.currentTarget.nodeName);
      var el = (_.isString(e) ? '' : $(e.currentTarget));
      var data = (_.isString(eventName) && eventName.length > 0) ? eventName : (el.is('[data-track]') ? el.attr('data-track') : undefined);

      switch (tag.toLowerCase()) {
      case 'a':
        category = 'link';
        action = el.text() + ' - ' + el.prop('href');
        break;
      case 'button':
        category = 'button';
        action = el.text();
        break;
      case 'input':
        category = 'button';
        action = el.val() + ' - ' + el.prop('type');
        break;
      }

      category = (_.isString(eventName) ? (eventName == '' ? 'custom' : e) : category);
      action = (!_.isUndefined(data) ? data : action);

      if ( this.controller.googleEnabled ) {
        trackCustomEvent( action, '', category );
      }

    },
    /**
     * [calculateLayout description]
     * @param  {[type]} timestamp [description]
     * @return {[type]}           [description]
     */
    calculateLayout: function () {
      // this animation interval should only run when the app is being iframed
      if (top === self) {
        return;
      }

      var newHeight = ((this.$modal.children().length > 0 && $('.modal-window', this.$modal).outerHeight() > this.$body.outerHeight()) ? $('.modal-window', this.$modal).outerHeight() : this.$body.outerHeight());

      // no change detected... back to go
      if (newHeight === this.currentHeight) {
        return;
      }

      // update the frame height
      this.currentHeight = newHeight;
      if (this.pym) {
        this.pym.sendHeight();
      }
    },
    /**
     *
     */
    scrollToTop: function () {
      if (top !== self) {
        if (this.pym) {
          this.pym.scrollParentTo(this.pymChildId);
        }
      } else {
        // PCA-588: Firefox will only scroll to top if html height is 100%. But it'll only allow page scroll if html is auto
        $('html').height('100%');
        $("html, body").animate({ scrollTop: 0 }, 'fast', function() {
          $('html').height('auto');
        });
      }
    },

    manualOAuth: function (token) {
      token = token.split('&')[0];
      PubSub.trigger('fbManualAuth', token);
      var state = ROUTES.LANDING;
      if (window.location.hash.indexOf('state=') > -1) {
        state = decodeURIComponent(window.location.hash.split('state=')[1].split('&')[0]);
      }
      PubSub.trigger('navigate', state);
    }
  });

};
