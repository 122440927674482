'use strict';
var Backbone = require("backbone");

module.exports = Backbone.Model.extend({
  url: '',
  defaults: function () {
    return {
      name: "",
      id: "",
      group_id: "",
      active: "0",
      image: "",
      voteData: [],
      voteCollection: undefined,
      remainingVotes: null,
      regions: [],
      slug: ""
    }
  }
});
