"use strict";

var _ = require("lodash");
var Backbone = require("backbone");
var Model = require("./../models/category-group");
var APP_REGION = require("./../constants").APP_REGION;

module.exports = Backbone.Collection.extend({
  model: Model,
  allModels: [],
  //hashmap of category ids to map to index in allModels array
  catIdHash: {},
  catGroupIdHash: {},
  appRegion: "",
  // Array of group ids that are available to user based on app region
  availableGroupsIds: [],
  /**
   *
   */
  initialize: function (models, options) {
    _.bindAll(this, "getCategoryById");
    this.appRegion = options.appRegion || APP_REGION.WORLDWIDE;
    Backbone.Collection.prototype.initialize(models.options);
  },

  comparator: function (a, b) {
    return parseInt(a.get("order"), 10) - parseInt(b.get("order"), 10);
  },

  setUpAvailableGroups: function() {
    var availableGroups = [];
    this.models.map((item) => {
      if(item.get('region').indexOf(APP_REGION.WORLDWIDE) > -1 ||
             item.get('region').indexOf(this.appRegion) > -1){
        availableGroups.push(item.get('id'));
      }
    })

    this.availableGroupsIds = availableGroups;
  },

  setUpAllModels: function () {
    //set up all models and hash
    this.allModels = [];
    this.forEach(
      function (item, index) {
        this.allModels = this.allModels.concat(
          item.get("categoryCollection").models
        );
        this.catGroupIdHash[item.id] = index;

        var regions = item.get("region") || APP_REGION.WORLDWIDE;
        item.set("region", regions.split(",").map((e)=>e.trim()));

      }.bind(this)
    );
    this.allModels.forEach(
      function (item, index) {
        this.catIdHash[item.id] = index;
      }.bind(this)
    );
  },
  /**
   * [getCategoryById description]
   * @param  {[type]} categoryId [description]
   * @return {[type]}             [description]
   */
  getCategoryById: function (categoryId) {
    var found;
    this.forEach(function (item) {
      if (found) {
        return true;
      }

      found = item.get("categoryCollection").get(categoryId);
    });
    return found;
  },

  getCategoryBySlug: function (group, slug) {
    var category = null;

    if (group) {
      var groupModel = this.models.find(function(item){
        if(item.id === group) return item;
      })
      category = groupModel.get("categoryCollection").models.find(function(cat) {
        if(cat.attributes.slug === slug) return cat;
      });
    } else if (!group && !category) {
      this.models.forEach(function(item){
        if(category) return;

        item.get("categoryCollection").models.find(function(cat) {
          if(cat.get('slug') === slug) { category =  cat };
        });
      })
    }

    return category;
  },

  getGroupByCategory: function (cat) {
    var group = null;
    var id = cat.get('group_id');

    group = this.models.find(function(item){
      return item.get('id') === id
    })

    return group;
  },

  getGroupBySlug: function (slug) {
    var group = this.models.find(function(item){
      if(item.get('slug') === slug) return item;
    })

    return group;
  },

  getGroupById: function (id) {
    var group = this.models.find(function(item){
      if(item.get('id') === id) return item;
    })

    return group;
  },
  /**
   * [getCategoryById description]
   * @param  {[type]} categoryId [description]
   * @return {[type]}             [description]
   */
  getCategoryName: function (categoryId) {
    var found;
    this.forEach(function (item) {
      if (found) {
        return true;
      }

      found = item.get("categoryCollection").get(categoryId);
    });
    return found && typeof found.attributes === "object"
      ? found.attributes.name
      : "";
  },
  /**
   * [getCategoryById description]
   * @param  {[type]} categoryId [description]
   * @return {[type]}             [description]
   */
  getCategoryHashtag: function (categoryId) {
    var found;
    this.forEach(function (item) {
      if (found) {
        return true;
      }

      found = item.get("categoryCollection").get(categoryId);
    });
    return found && typeof found.attributes === "object"
      ? found.attributes.hashtag
      : "";
  },
  /**
   * [getNextCategoryById description]
   * @param  {[type]} categoryId [description]
   * @return {[type]}             [description]
   */
  getNextCategoryById: function (categoryId) {
    var found;
    var currentIndex;
    var nextIndex;

    currentIndex = this.catIdHash[categoryId] || 0;

    nextIndex =
      currentIndex + 1 >= this.allModels.length ? 0 : currentIndex + 1;

    while (
      this.availableGroupsIds.indexOf(this.allModels[nextIndex].get('group_id')) < 0
    ) {
      nextIndex =
        nextIndex + 1 >= this.allModels.length ? 0 : nextIndex + 1;
    }
    found = _.at(this.allModels, nextIndex)[0];

    return found;
  },
  /**
   * [getPrevCategoryById description]
   * @param  {[type]} categoryId [description]
   * @return {[type]}             [description]
   */
  getPrevCategoryById: function (categoryId) {
    var found;
    var currentIndex;
    var prevIndex;

    currentIndex = this.catIdHash[categoryId] || 0;

    prevIndex =
      currentIndex - 1 < 0 ? this.allModels.length - 1 : currentIndex - 1;

    while (
      this.availableGroupsIds.indexOf(this.allModels[prevIndex].get('group_id')) < 0 ) {
      prevIndex =
        prevIndex - 1 < 0 ? this.allModels.length - 1 : prevIndex - 1;
    }
    found = _.at(this.allModels, prevIndex)[0];

    return found;
  },
  getPrevCategoryName: function (categoryId) {
    var found;
    var currentIndex;
    var prevIndex;

    currentIndex = this.catIdHash[categoryId] || 0;
    prevIndex =
      currentIndex - 1 < 0 ? this.allModels.length - 1 : currentIndex - 1;
    while (
      this.availableGroupsIds.indexOf(this.allModels[prevIndex].get('group_id')) < 0
    ) {
      prevIndex =
        prevIndex - 1 < 0 ? this.allModels.length - 1 : prevIndex - 1;
    }
    found = _.at(this.allModels, prevIndex)[0];

    return found && typeof found.attributes === "object"
      ? found.attributes.name
      : "";
  },
  getNextCategoryName: function (categoryId) {
    var found;
    var currentIndex;
    var nextIndex;

    currentIndex = this.catIdHash[categoryId] || 0;
    nextIndex =
      currentIndex + 1 >= this.allModels.length ? 0 : currentIndex + 1;
    while (
      this.availableGroupsIds.indexOf(this.allModels[nextIndex].get('group_id')) < 0
    ) {
      nextIndex =
        nextIndex + 1 >= this.allModels.length ? 0 : nextIndex + 1;
    }
    found = _.at(this.allModels, nextIndex)[0];

    return found && typeof found.attributes === "object"
      ? found.attributes.name
      : "";
  },
  getCategoryCount: function () {
    return this.allModels.length;
  },
  getCategoryIndexById: function (categoryId) {
    var currentIndex;

    currentIndex = this.catIdHash[categoryId] || 0;


    return currentIndex;
  },
  getCategoryByIndex: function (index) {
    return this.allModels[index];
  },
  /**
   * [getOptionById description]
   * @param  {[type]} optionId [description]
   * @return {[type]}           [description]
   */
  getOptionById: function (optionId) {
    var found;
    this.forEach(function (item) {
      if (found) {
        return true;
      }

      item.get("categoryCollection").forEach(function (item) {
        if (found) {
          return true;
        }
        found = item.get("voteCollection").get(optionId);
      });
    });
    return found;
  },
  /**
   * Fetches the model by categoryId and id
   * @param  {String} key : comes is a form of category-id (e.g. 2-A1)
   * @return {model} : the model that was found
   */
  getOptionByCatAndNominee: function (category, nominee, param) {
    var found;

    this.forEach(function (item) {
      if (found) {
        return true;
      }
      item.get("categoryCollection").forEach(function (item) {
        if (item.get(param) === category && !found) {
          item.get("voteCollection").forEach(function (item) {
            if(item.get(param) === nominee && !found) {
              found = item;
              return true;
            }
          })
        }
      });
    });
    return found;
  },
  /**
   *
   */
  getCategoriesByGroup: function (groupId) {
    return this.allModels.filter(function (cat) {
      return cat.get("group_id") === groupId;
    });
  },
  /**
   * [getNextCategoryGroupById description]
   * @param  {[type]} groupId [description]
   * @return {[type]}             [description]
   */
  getNextCategoryGroupById: function (groupId) {
    var found;
    var currentIndex;
    var nextIndex;

    currentIndex = this.catGroupIdHash[groupId] || 0;

    nextIndex =
      currentIndex + 1 >= this.models.length ? 0 : currentIndex + 1;

    while (
      this.availableGroupsIds.indexOf(this.models[nextIndex].get('id')) < 0
    ) {
      nextIndex = nextIndex + 1 >= this.models.length ? 0 : nextIndex + 1;
    }
    found = _.at(this.models, nextIndex)[0];

    return found;
  },
  /**
   * [getPrevCategoryGroupById description]
   * @param  {[type]} groupId [description]
   * @return {[type]}             [description]
   */
  getPrevCategoryGroupById: function (groupId) {
    var found;
    var currentIndex;
    var prevIndex;

    currentIndex = this.catGroupIdHash[groupId] || 0;
    prevIndex =
      currentIndex - 1 < 0 ? this.models.length - 1 : currentIndex - 1;

    while (
      this.availableGroupsIds.indexOf(this.models[prevIndex].get('id')) < 0
    ) {
      prevIndex =
        prevIndex - 1 < 0 ? this.models.length - 1 : prevIndex - 1;
    }
    found = _.at(this.models, prevIndex)[0];

    return found;
  }
});
