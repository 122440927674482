'use strict';
var Backbone = require("backbone");

module.exports = Backbone.Model.extend({
  url: '',
  defaults: function () {
    return {
      name: "",
      id: "",
      active: "0",
      image: "",
      backgroundColor: "",
      categoryData: [],
      categoryCollection: undefined,
      region: [],
      slug: ""
    }
  }
});
