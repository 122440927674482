'use strict';
const _ = require("lodash");
const Backbone = require("backbone");
const template = require('./../templates/header.ejs');
const templateCss = require('./../templates/header-css.ejs');
const BaseView = require('./../views/base');
const PubSub = require('tscom-pubsub');
const CSSView = require('./css');
const { logExitEvent } = require("../util/mparticle");
const isMobile = require('./../util/helpers').isMobile;
var styleId;

module.exports = BaseView.extend({
  className: 'view-header',
  events: function () {
    return _.extend(_.result(BaseView.prototype, 'events'), {
      'click .sponsor-bug a': 'onSponsorClick'
    })
  },
  /**
   *
   */
  initialize: function (options) {
    _.bindAll(this, 'render');

    this.ads = options.ads;
    styleId = this.className + _.uniqueId();
    this.$el.addClass(styleId);
    this.listenTo(this.model, 'change:copy', this.render);
    this.listenTo(this.model, 'change:ads', this.render);

    this.listenTo(this.model, 'change:copy', this.render.bind(this));
    this.listenTo(PubSub, 'categorySelected', this.storeCategory.bind(this))
    this.listenTo(PubSub, 'renderMainView', function (route) { this.renderAds(route) }.bind(this))
  },
  /**
   *
   */
  render: function () {

    this.CSSView = new CSSView({
      model: this.model.get('copy'),
      namespace: '.' + styleId,
      template: templateCss
    });

    var link = '';
    if (isMobile()) {
      link = this.model.get('copy').logo.link.mobile_url || '';
    } else {
      link = this.model.get('copy').logo.link.url || '';
    }

    Backbone.$('head').append(this.CSSView.render().$el);
    this.$el.html(template(_.extend(
      {},
      this.model.get('copy'),
      {
        logoLink: link
      }
    )));

    this.renderAds();

    this.$('.logo img').on('load', function () { PubSub.trigger('headerLoaded'); });

    return this;
  },
  onSponsorClick: function() {
    const { sponsor_bug: sponsorBug } = this.model.get('copy');
    logExitEvent(sponsorBug.sponsor_name);
  },
  /**
   * [renderCategory description]
   * @return {[type]} [description]
   */
  storeCategory: function (categoryCollection) {
    if (!categoryCollection) {
      return;
    }
    this.categoryCollection = categoryCollection;
  },
  /**
   * [renderAds description]
   * @return {[type]} [description]
   */
  renderAds: function () {
    //PCA-914 static ad update
    // this.$('.aside-logo').html(new ViewAd({
    //   model: this.ads.entitlement
    // }).render().$el);
  },

  remove: function() {
    if (this.CSSView) {
      this.CSSView.remove();
    }
  }
});
