'use strict';
var template = require('./../templates/iframe.ejs');
var BaseView = require('./../views/base');

module.exports = BaseView.extend({
  tagName: 'div',
  className: 'view-iframe',
  /**
   *
   */
  render: function () {
    this.$el.html(template(this.model));
    return this;
  },
  /**
   * 
   */
  renderedToDom: function() {
    if(window.Telescope) {
      window.Telescope.loadApplication({
        id: this.model.id,
        url: this.model.url
      });
    }
  }
});
