module.exports = {

  WRITE_IN: {
    CONTESTANT_ID: 'Z9'
  },

  FACEBOOK: {
    VERSION: 'v15.0',
    MANUAL_LOGIN_DEVICES: ['native', 'canvas'],
    GRAPH_URI: 'https://graph.facebook.com/v15.0/',
    OAUTH_URI: 'https://www.facebook.com/v15.0/dialog/oauth?',
    SHARE_URI: 'https://www.facebook.com/v15.0/dialog/share?'
  },
  
  APP_STATE: {
    OPEN: 'OPEN',
    COUNTDOWN_CLOSED: 'COUNTDOWN-CLOSED',
    VOTING_CLOSED: 'VOTING-CLOSED',
    WINNERS: 'WINNER-CLOSED'
  },

  APP_REGION: {
    WORLDWIDE: 'worldwide',
    US: 'us'
  },

  CAPTCHA: {
    SITE_KEY: "6LfOP2kUAAAAAF9XQCm-DW1hdk2L20skSKZubbeK"
  },

  DEVICE: {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop',
    WIDGET: 'embed',
    ANDROID: 'android',
    IOS: 'ios'
  },

  WIDGET_QSP: 'widget',

  SITE_TYPE: {
    WIDGET: 'embed-widget',
    WEB: 'web'
  },

  OPTINS: {
    NEWSLETTER: 'newsletter',
    UPDATES: 'updates'
  },

  OPTINS_ACCEPTANCE: {
    YES: 'yes',
    NO: 'no'
  },

  POLL: {
    CMS_DEFAULT: 30,
    CMS_MIN_POLL: 3
  },

  SHARE_VOTE: {
    FACEBOOK: 'fb_share',
    TWITTER: 'tw_share'
  },

  SORTING_METHOD: {
    ALPHABETICAL: 'alphabetical',
    CUSTOM: 'custom',
    RANDOM: 'random'
  },

  SUBNAV: {
    SPONSOR_QSP: '?source=subnav-sponsored&cmpid='
  },

  ROUTES: {
    LANDING: 'landing',
    VOTE_DETAIL: 'vote',
    OVERLIMIT: 'overlimit',
    THANKS: 'thanks',
    WINDOW: 'window',
    GEO: 'geo',
    AUTH: 'auth',
    ERROR: 'error',
    ERROR_VOTE: 'vote',
    TERMS: 'terms',
    SMS: 'smsterms',
    FAQ: 'faq',
    RULES: 'rules',
    INFO: 'photo_video_info'
  },

  SLUGIFY: {
    REGEX: /[*+~.()'"/!:@?,=]/g
  },
  CONNECT: {
    VERSION_CHECK: 'gZ7TaEQs5g3jSpA4MAcv',
    VERSION_CHECK_MAIN: 'hAMkDgfWzkDIWRV6krH9',
    VERSION_CHECK_OPTIN: 'DgXyXFYCXdSJdczsQK8j'
  },
  AUTH: {
    EMAIL: 'email',
    FACEBOOK: 'fb',
    AUTH_TYPE: {
      REAUTH: 'reauthorize',
      REREQUEST: 'rerequest'
    },
    PERMISSION_STATUS: {
      GRANTED: 'granted',
      DECLINED: 'declined'
    }
  }
}
